.npSurveyWrapper {
  background-color: #fff;
  border-radius: 15px;
  width: 96%;
  margin: 30px auto 15px;
}
.addQuestion {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.toolSurveyHeader h6 {
  min-width: 126px;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif
}
.toolSurveyHeader h6.surTotalQue {
  color: #B2B2B2;
}
.toolSurveyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0 15px;
}
.addQuestion .descriptionSubmitBtn {
  width: 100%;
  background-color: #009BE2;
  box-shadow: none;
  border-radius: 8px;
  height: 45;
  text-transform: capitalize;
  color: #fff;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif
}
.addQuestion .descriptionSubmitBtn:hover {
  background-color: #009BE2;
  color: #fff;
  box-shadow: none;
}
.addQuestion .descriptionSubmitBtn .addIcon {
  font-size: 35px;
  padding-right: 10px;
}
.addQuestionBox {
  border-left: 1px solid #ccc;
  padding-left: 20px;
}
.labelAndInput,
.labelAndInputFlex {
	padding: 0;
	margin-bottom: 10px;
}
.labelAndInputFlex {
  display: flex;
  gap: 5px;
  position: relative;
}
.pointer {
  cursor: pointer;
}
.labelAndInput label,
.labelAndInputFlex label {
	font-family: 'Rene_Bieder_Milliard_SemiBold', sans-serif;
	font-size: 20px;
	line-height: 40px;
}
.labelAndInput input,
.labelAndInputFlex input {
	font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
	padding: 13px 20px;
	border-radius: 12px;
	font-size: 16px;
}
.labelAndInput p,
.labelAndInputFlex p {
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.textFieldDesigned {
	width: 100%;
}
.textFieldDesigned > div {
  border-radius: 12px;
}
.textFieldDesigned > div fieldset,
.textFieldDesigned > div:focus fieldset,
.textFieldDesigned > div:hover fieldset  {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
.surveyFormWrap {
  padding: 15px;
  width: 98%;
}
.radioSurveySection {
  display: flex !important;
  width: 100%;
}
.radioSurveySection label input[type="radio"]:checked + div svg {
  fill: #009BE2;
}
.descSurveyBox {
  background-color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.descSurveyBox span {
  color: #B2B2B2;
  font-size: 15px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.descSurveyBox:hover {
  border: 1px solid #009BE2;
}
.descSurveyBox:hover span {
  color: #009BE2;
}
.descSelectAnswer  {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}
.descSelectAnswer .answerSelectSection {
  flex-direction: row;
  gap: 20px;
  margin: 20px 0;
}
.descSelectAnswer .answerSelectSection input {
  height: 45;
  width: 18;
}
.descSelectAnswer .answerSelectSection span {
  padding-left: 5px;
  font-size: 14px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.descSelectAnswer span {
  font-size: 14px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
  margin-right: -1px;
}
.descSelectAnswer label  input + svg {
  fill: #fff;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.23);
  height: 20px;
  width: 20px;
}
.descSelectAnswer label  input:checked + svg {
  background-color: #009BE2;
  color: #fff !important;
  border: 0px solid #009BE2;
}
.labelAndInput label.labelAndInputQue {
  font-size: 16px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.labelAndInputQue {
  font-family: 'Rene_Bieder_Milliard_SemiBold', sans-serif;
	font-size: 16px;
	line-height: 32px;
}
.surveySelectBox {
  margin: 34px 0 10px auto;
  text-align: right;
  width: 62%;
}
.surveySelectBox .textFieldDesigned > div {
  background-color: #f1f1f1;
}
.surveySelectBox .textFieldDesigned input {
  padding: 4px 6px !important;
}
.surveySelectBox .textFieldDesigned input::placeholder {
  color: #000;
}
.surveySelectImg {
  filter: invert(1);
  margin-right: 15px;
}
.descChooseAnswer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  width: 62%;
}
.surveyQueMain {
  margin-left: auto;
}
.descChooseAnswer p {
  font-size: 14px;
}
.descChooseAnswer select {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.checkSurveySection {
  display: block !important;
  width: 100%;
}
.checkSurveySection input.surveyCheckBox {
  cursor: pointer;
}
.surveyCheckBox {
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 10px;
  width: 80%;
  margin: 0 0 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.surveyCheckBox span {
  color: #B2B2B2;
  font-size: 15px;
  padding-left: 5px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.surveyCheckBox:hover {
  border: 1px solid #009BE2;
}
.surveyCheckBox:hover span {
  color: #009BE2;
}
.addQuestionBottomBtn {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 30px 0;
}
.surveyFormWrap .createBtn {
  padding: 8px 25px;
  background-color: #009BE2;
  box-shadow: none;
  border-radius: 8px;
  text-transform: capitalize;
  color: #fff;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.surveyFormWrap .createBtn:hover {
  background-color: #009BE2;
  box-shadow: none;
}
.surveyFormWrap .cancelBtn {
  padding: 8px 25px;
  border: 1px solid rgba(0, 0, 0, 0.23) ;
  background-color: transparent;
  box-shadow: none;
  border-radius: 8px;
  text-transform: capitalize;
  color: #009BE2;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.surveyFormWrap .cancelBtn:hover {
  background-color: transparent;
  color: #009BE2;
  box-shadow: none;
}
.valueOptionField {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  position: relative;
}
.valueOptionField h6 {
  margin: 0;
}
.valueOptionField > div {
  position: relative;
}
.valueOptionField select { 
  appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  background-color: #fff;
  width: 50px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Rene_Bieder_Milliard_Semibold', sans-serif;
}
.valueOptionField > div:after {
  position: absolute;
  content: "";
  top: 15px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #000 transparent transparent transparent;
}
.commSelectOptions {
  display: flex;
  gap: 5px;
}
.commSelectOptions .surveyCheckBox {
  display: flex;
  gap: 15px;
}
.commSelectOptions .surveyCheckBox input {
  border: none;
  padding: 0;
  width: 100%;
}
.commSelectOptions .surveyCheckBox svg {
  color: rgba(0, 0, 0, 0.23);
  font-size: 22px;
}
.deleteIcon img {
  filter: sepia(1);
  opacity: 0.4;
  height: 20px;
  width: 20px;
}
.labelAndInputRateWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.labelAndInputRating {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 80%;
}
.labelAndInputRating label {
  font-size: 16px;
  font-family: 'Rene_Bieder_Milliard_Semibold', sans-serif;
}
.labelAndInputRating .textFieldDesigned input {
  padding: 5px 14px;
  font-family: 'Rene_Bieder_Milliard_Semibold', sans-serif;
}
.labelAndInputRating .textFieldDesigned > div fieldset {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 0;
}
.rateQuetionBlocks {
  padding-bottom: 20px;
}

/* Select  */
.label {
  display: inline-block;
  /* font-weight: 400px !important; */
  font-size: 18px !important;
  margin-bottom: 5px !important;
  padding-left: 5px;
}
*:focus {
  outline: none;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
.labelHeading {
  display: inline-block;
  font-size: 18px !important;
  margin-bottom: 5px !important;
  /* padding-left: 10px; */
}
.labelWhoareWe {
  display: inline-block;
  font-size: 18px !important;
  font-weight:600 !important;
  padding-left: 10px !important;
  margin-left: 17px !important;
  margin-top: 10px !important
}
.cardUploadImage {
  padding: 5px 12px 0 12px !important;
}
.heading {
  display: inline-block;
  font-size: 22px !important;
  margin-left: 10px;
}
  
.mainWrapper {
  padding: 15;
  /* width: 90%; */
  border-radius: 12 !important;
  margin: auto;
  margin-top: 30px;
}
.textInput {
  width: 50%;
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  background: #ffffff;
  border: 1px solid #d9d4d4;
  font-size: 16px;
}

.selectInput {
  margin-bottom: 10px;
  border-radius: 12;
  width: 100%;
}
.root {
  border-radius: 12px !important;
}
.root :focus {
  border: none;
}
.focused {
  border-color: red;
}
.notchedOutline {
  border-color: red;
}
.notchedOutline :hover {
  border-color: red;
}

.multiLineInput {
  /* height: 150px; */
  width: 50%;
  border: 1px solid #d9d4d4;
  border-radius: 12px;
  padding: 13px !important;
}
/* .multiLineInput :placeholder-shown {
  background-color: red;
  margin-top: -20px;
} */
.divWrapper {
  margin-bottom: 20px;
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 0px;
}
.divWrapperInput{
  max-width: 345px;
}
.divWrapperInput fieldset{
  border: none !important;
}
.divWrapperInput input::placeholder {
  opacity: 1;
}
.activeEventTabs {
  color: #009be2 !important;
  border: none !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 8px rgba(0, 61, 89, 0.5) !important;
  border-right: 2px solid #009be2 !important;
  /* color: #009be2 !important;
  border: none !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 8px 0px rgb(0 155 226 / 21%) !important;
  border-right: 2px solid #009be2 !important; */
}

.sdContainer {
  position: relative;
  float: left;
}

.sd {
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.openButton {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.openButton button {
  border: none;
  background: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  /* background-color: red; */
  color: red;
  /* -webkit-appearance: none; */
}

.locationField {
  border-radius: 12px !important;
}

.newEventTitle {
  display: flex;
  justify-content: space-between;
}
.userModal {
  border-radius: 12px;
}
.modalDiv {
  padding: 10;
  border-bottom: 2px solid #eaeaea;
  display: flex;
}

.modalDivEnd {
  padding: 10;
  display: flex;
}

.modalTitle {
  padding: 10;
  border-bottom: 2px solid #eaeaea;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userName {
  font-size: 16px !important;
}

.userEmail {
  font-size: 12px !important;
}
.modalHeader {
  font-size: 14px !important;
}
.modalText {
  font-size: 14px !important;
}
.calenderIconBox {
  height: 25;
  width: 25;
  position: "absolute";
  top: 13;
  right: 10;
}
.clockIconContainer {
  height: 18px;
  width: 17px;
  position: absolute;
  top: 50px;
  right: 51%;
  background-color: #fff;
  pointer-events: none;
}
.modalBox {
  height: auto;
  background-color: white;
  position: relative;
  top: 22%;
  text-align: center;
  left: 45%;
  width: 30%;
  border-radius: 15px;
}
.warningIcon {
  margin: 40px 0 20px 0;
}
.optionsLabel {
  margin-top: 55px !important;
}
.checked {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  position: relative;
}

.blue {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 4px;
  right: 4px;
  background-color: #009be2;
  border-radius: 2px;
}

.unchecked {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  position: relative;
}
.textFieldDesigned > div:focus fieldset,
.textFieldDesigned > div:hover fieldset  {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
.option {
  background-color: #000,
}
.surDialogTitle {
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
  font-size: 1.125rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 20px;
  border-bottom: 1px solid #ccc;
}
.surModalRadio {
  padding: 20px;
}
.surModalRadio .labelAndInputQue {
  font-size: 1rem;
}
.surModalRadio .labelAndInput .labelAndInputQue {
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.surveyModalRadio span {
  color: #B2B2B2;
  font-size: 15px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.surveyModalRadio .labelAndInput {
  padding: 0;
}
.surveyModalRadio span + span {
  color: #000;
}
.surveyModalRadio:hover span {
  color: #009BE2;
}
.customSurveyModalRadio {
  display: flex;
  align-items: center;
}
.customSurveyModalRadio label {
  color: #000;
  font-size: 15px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
  padding-left: 12px;
}
.customSurveyModalRadio:hover label {
  color: #009BE2;
}
.customSurveyModalRadio input {
  width: 20px;
  height: 20px;
  margin: 0;
}
.dateSelectVal{
    background-color: #f1f1f1;
    border: 1px solid #ccc !important;
    border-radius: 12px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0px !important;
}
.dateSelectVal > div{
  box-shadow: none !important;
  width: 100% !important;
}
.surModalSelectVal {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 12px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.surModalSelectVal h6 {
  font-size: 14px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.surModalSelectVal button {
  padding: 0;
  min-width: auto;
}
.calenderBtns {
  align-items: center;
  justify-content: space-between;
  display: none;
}
.surveyModalFormWrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 30px 15px;
}
.surveyModalFormWrap .sendBtn {
  padding: 8px 25px;
  background-color: #009BE2;
  box-shadow: none;
  border: 1px solid #009BE2;
  border-radius: 8px;
  text-transform: capitalize;
  color: #fff;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.surveyModalFormWrap .sendBtn:hover {
  background-color: #009BE2;
  box-shadow: none;
}
.surveyModalFormWrap .saveBtn {
  padding: 8px 25px;
  border: 1px solid rgba(0, 0, 0, 0.23) ;
  background-color: transparent;
  box-shadow: none;
  border-radius: 8px;
  text-transform: capitalize;
  color: #009BE2;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.surveyModalFormWrap .saveBtn:hover {
  background-color: transparent;
  color: #009BE2;
  box-shadow: none;
}
.surveyCompleteText {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.surveyCompleteText svg {
  color: #008000;
  font-size: 70px;
}
.surveyCompleteText h6 {
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.surveyCompleteText .congratesText {
  font-size: 16px;
  color: #000;
  font-family: "Rene_Bieder_Milliard_Book", sans-serif;
}
.customSelectWrapper {
  position: relative;
}
.customSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 12px;
  padding: 10px;
  background-color: #ddd;
  cursor: pointer;
}
.customSelectIcon {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
.customSelectIcon svg {
  color: rgba(0, 0, 0, 0.23);
}
.customSelect .customSelectText {
  font-size: 14px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.customSelectShow {
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  padding: 12px 18px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0px #f1f1f1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.customSelectInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 15px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.customSelectInner:last-child {
  padding: 15px 0 10px;
  border-bottom: 1px solid transparent;
}
.customSelectInner .customSelectIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.customSelectInner .customSelectIcon svg {
  color: #0000003b;
}
.customSelectInner .customSelectText{
  font-size: 16px;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.customSelectInner:hover .customSelectText,
.customSelectInner:hover .customSelectIcon {
  color: #009BE2;
}

@media (max-width: 1024px) {
  .surveySelectBox {
    width: 100%;
  }
  .surModalSelectVal {
    width: 100%;
  }
}