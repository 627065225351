.followersBusmainDiv {
    background-color: #fff;
    border-radius: 15px;
}
.followersBusmainDiv1 {
    background-color: #fff;
    margin-top: 30px;
    border-radius: 15px;
}

.followersBusheadingDiv {
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.followersBusheading h3 {
    font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.modalBox {
    position: absolute;
    height: 480px;
    width: 648px;
    background-color: #fff;
    top: 540;
    left: 303px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 12%);
    border-radius: 10px;
  }

.searchField {
    width: 35rem;
    border-radius: 12px;
    height: 50px !important;
    padding: 10px;
    background: #fafcfe;
    border: 1px solid #dee1ea;
    font-size: 30px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
    opacity: 0.5;
}


.confirmDialogStyle {
    padding: 40px 40px 20px;
    border-radius: 16;
    width: 500px;
    box-shadow: 0px 0px 8px rgba(147, 145, 145, 0.5);
}

.rejectText {
    font-weight: bold;
    font-size: 16px !important;
    line-height: 27px;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
    margin: 0;
}


.confirmBtn {

    height: 45px;
    margin: 20px 0 !important;
    color: #fff !important;
    background: #009be2 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: center;
    border-radius: 12px !important;
    border: 1px solid #009be2 !important;
    text-transform: capitalize !important;
    width: 40%;
    padding: 10px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;

}

.cancelBtn {
    height: 45px;
    margin: 20px 0 !important;
    color: black !important;
    font-size: 16px !important;
    text-align: center;
    border-radius: 12px !important;
    border: 1px solid #979797 !important;
    text-transform: capitalize !important;
    width: 40%;
    padding: 10px !important;
    margin-right: 20px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
    font-weight: bold !important;

}

.buttonsParent {
    display: flex;
    justify-content: space-around;
}

.errorTextParent {
    display: flex;
    align-items: center;
    flex-direction: column;

}

.errorTextParent .error {
    color: red;
    padding: 5px;
    height: 42px;
    width: 65px;
    font-size: 25px;
    margin-bottom: 10px;
}

.dialogHeadingcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.messageIconStyle {

    height: 30px;
    cursor: pointer
}

.modalMessageText {
    margin-left: -7px;
    font-size: 12px;
    color: #1593CA;
    margin-top: 5px;
}

.p15 {
    padding: 15px;
}

.ml20 {
    margin-left: 20px !important;
}

.displayFlex {
    display: flex;
}

.loaderStyle {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.broadcastMessage {
    padding: 25px 0px;
    width: 40%;
}

.messageButton {
    display: flex;
    justify-content: flex-end;
}

.textArea{
    background-color: #fff;
    border: none;
    border-radius: 15px;
    margin: 10px 0px;
    padding: 13px !important;
}
.textCount{
    position: absolute;
}
div[class*='MuiAccordion-rounded']:last-child {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}
.listBroadcasts{
  display: flex;
  justify-content: space-between;
  margin-top: 5px !important;
}
.listDesc {
    margin: 0 80px !important;
    width: 650px !important;
    overflow-wrap: break-word !important;
    text-align: justify !important;
}
.listWrap {
    margin: 0 80px !important;
    width: 650px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
.listTime {
  margin-left: 10px !important;
}
div[class*='MuiAccordion-root'] {
    position:  unset !important;
  }

div[class*='MuiPaper-elevation1'] {
    box-shadow: none !important;
}