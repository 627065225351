 /* Customizable Area Start */
.advanceSearchToolBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  align-items: center;
  margin-top: 30px;
}

.advancesearchreletiveenv{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.474186);
  border-radius: 13px;
  height:54px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 77%;
}

.notselecteddbsButton{
  background: #FFFFFF !important;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.474186);
  border-radius: 13px !important;
  color: black !important;
  max-width:64px;
  height:56px;
}

.selecteddbsButton{
  background: orange !important;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.474186);
  border-radius: 13px !important;
  color: white !important;
  max-width:64px;
  height:56px;
}

.inputRoot {
  color: black;
}

.inputInput {
  padding: 4px 4px 4px 0px;
}

.dbsandavilsearchMain{
  position: inherit;
  width: inherit;
  height: 100vh;
}

.modalInSearchInput{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  height: 54px;
}

.modalInProximitySearchInput{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  height: 54px;
}

.modalavailibilityLabel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0;
  margin-top:30px;
}

.dbssearchfilterEnv {
  position: relative;
  top: 242px;
  padding: 10px;
  width: inherit;
  height: 301px;
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5) !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-button {
  width: 50px;
  /*height: 50px;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(212, 212, 212, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(212, 212, 212, 0.8);
}

.catdropdownmenubackground {
  height: 280px;
  overflow-y: auto;
}
.gridContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem
}
.searchreletiveenv {
  position: relative;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.8);
  border-radius: 12px;
  z-index: 2;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  -webkit-box-align: center;
  cursor: default;
  -webkit-box-pack: justify;
  transition: all 100ms ease 0s;
}

.dbs_tabs_logo {
  width: 18px;
  height: 8px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  width: auto;
}

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}



.modalSearchMain{
  position: absolute;
  width: 100%;
  height: 280px;  
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  z-index: 2;
}


.hidemodalSearchMain{
  position: relative;
  width: 100%;
  z-index: 4;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
}

.proximitySearchMain{
  position: absolute;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  z-index: 4;
}



.hideProximitySearchMain{
  position: relative;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
}

 /* Customizable Area End */





 .dateBtn{
  background-color: transparent;
  border: none;
  min-width: auto !important;
}
