.mainWrapper {
    position: absolute;
    left: 344px;
    top: 140px;
    width: 79%;
    height: 1015px;
    border-radius: 15px;
    background: #ffffff;
    background-blend-mode: normal;
}

.headerDiv {
    position: absolute;
    left: 44px;
    top: 45px;
    width: 312px;
    height: 29px;
}

.iconBack {
    position: absolute;
    left: 0px;
    top: 1px;
    width: 24px;
    height: 20px;
}

.paymentText {
    position: absolute;
    left: 52px;
    top: -30px;
    width: 300px;
    height: 29px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 24px;
    font-weight: 400;
}

.paymentDetailsDiv {
    position: absolute;
    left: 44px;
    top: 97px;
    width: 443px;
    height: 865px;
}

.paymentDetailsText{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 87px;
    height: 27px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.55px;
}

.cardDetailsDiv {
    position: absolute;
    left: 1px;
    top: 52px;
    width: 442px;
    height: 85px;
    border-radius: 12px;
    background: #ffffff;
    background-blend-mode: normal;
    box-shadow: 0px 0px 8px rgba(212,212,212,0.5);
}

.radioButon {
    position: absolute;
    left: 29px;
    top: 32px;
    width: 15px;
    height: 15px;
    background: #009be2;
    background-blend-mode: normal;
}

.cardLogo {
    position: absolute;
    left: 90px;
    top: 23px;
    width: 57px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    background: #ffffff;
    background-blend-mode: normal;
}

.visaLogo {
    position: absolute;
    top: -5px;
    left: 10px;
    color: #1434CB;
    font-weight: bold;
    font-style: italic
}

.cardNo {
    position: absolute;
    left: 185px;
    top: 25px;
    width: 140px;
    height: 28px;
}

.cardExpiry {
    position: absolute;
    left: 185px;
    top: 45px;
    width: 100px;
    height: 17px;
    color: #87888f;
    font-family:"Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.35px;
}

.anotherCard {
    position: absolute;
    left: 0px;
    top: 170px;
    width: 171px;
    height: 27px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.55px;
}

.emailDiv {
    position: absolute;
    left: 1px;
    top: 218px;
    width: 442px;
    height: 89px;
}

.emailText {
    position: absolute;
    left: 8px;
    top: 0px;
    width: 68px;
    height: 18px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.45px;
}

.emailFeild {
    position: absolute;
    left: 0px;
    top: 35px;
    width: 442px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.cardsDiv {
    position: absolute;
    left: 1px;
    top: 327px;
    width: 442px;
    height: 233px;
}

.cardsText {
    position: absolute;
    left: 8px;
    top: 0px;
    width: auto;
    height: 18px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.45px;
}

.cardNoFeild {
    position: absolute;
    left: 0px;
    top: 35px;
    width: 442px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.cardExpiryFeild {
    position: absolute;
    left: 0px;
    top: 104px;
    width: 220px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.cardCVVFeild {
    position: absolute;
    left: 240px;
    top: 104px;
    width: 202px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.cardHolderFeild {
    position: absolute;
    left: 0px;
    top: 173px;
    width: 442px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.regionDiv {
    position: absolute;
    left: 1px;
    top: 570px;
    width: 442px;
    height: 161px;
}
.regionText {
    position: absolute;
    left: 8px;
    top: 0px;
    width: auto;
    height: 22px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.45px;
}

.regionFeild {
    position: absolute;
    left: 0px;
    top: 29px;
    width: 442px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.zipFeild {
    position: absolute;
    left: 0px;
    top: 100px;
    width: 442px;
    height: 60px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 15px;
}

.savedSwitch {
    position: absolute;
    left: 1px;
    top: 740px;
    width: 363px;
    height: 24px;
}

.saveTxt {
    position: absolute;
    left: 55px;
    top: -15px;
    width: auto;
    height: 24px;
    color: #000501;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.payBtn {
    position: absolute;
    left: 0px;
    top: 800px;
    width: 443px;
    height: 48px;
    border-radius: 5px;
    background: #009be2;
    background-blend-mode: normal;
    color: white;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.375px;
    border: none;
}

.divider {
    position: absolute;
    left: 523px;
    top: 144px;
    width: 1px;
    height: 818px;
    border: 1px solid #bebebe;
}

/* .subscriptionDetailsDiv {

} */

.subscriptionDetailsText {
    position: absolute;
    left: 564px;
    top: 96px;
    width: auto;
    height: 27px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.55px;
}

.subsItems {
    position: absolute;
    left: 564px;
    top: 149px;
    width: 445px;
    height: 86px;
}

.subsName {
    position: absolute;
    left: 0px;
    top: 1px;
    width: 271px;
    height: 22px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.45px;
}

.subsPrice {
    position: absolute;
    left: 378px;
    top: 0px;
    width: 66px;
    height: 24px;
    font-family: "Rene_Bieder_Milliard_Bold", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    letter-spacing: -0.5px;
}

.betweenLine {
    position: absolute;
    left: 0px;
    top: 42.5px;
    width: 445px;
    height: 1px;
    border: 1px solid #bebebe;
}

.subsTotal {
    position: absolute;
    left: 0px;
    top: 55px;
    width: 40px;
    height: 22px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.45px;
}

.subsTotalPrice {
    position: absolute;
    left: 378px;
    top: 55px;
    width: 66px;
    height: 24px;
    font-family: "Rene_Bieder_Milliard_Bold", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    letter-spacing: -0.5px;
}

@media screen and (max-width: 1600px) {
    .mainWrapper {
        width: 74%;
    }
}

@media screen and (max-width: 1400px) {
    .mainWrapper {
        width: 70%;
    }

    .emailFeild, .cardHolderFeild, .cardNoFeild, .payBtn, .regionFeild, .zipFeild, .cardDetailsDiv {
        width: 380px;
    }
    .cardExpiryFeild {
        width: 180;
    }
    .cardCVVFeild {
        width: 180;
        left : 198px;
    }
    .divider {
        left: 475px;
    }
    .subscriptionDetailsText, .subsItems {
        left: 510px;
    }
    .subsPrice, .subsTotalPrice {
        left: 300px;
    }
    .betweenLine {
        width: 375px;
    }
}