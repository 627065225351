.imgCard {
    background-color: red;
}

.divWrapper {
    margin-bottom: 20px;
    padding-left: 10px;
}

.headingDiv {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.snackbar {
    background-color: #009be2 !important;
}

.multiLineInput {
    width: 50%;
    border: 1px solid #d9d4d4;
    border-radius: 12px;
    padding: 10px !important;
}

.heading {
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
    font-size: 22px !important;
    margin-left: 10px;
}

.label {
    display: inline-block;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif !important;
    font-size: 18px;
    margin-bottom: 5px !important;
    padding-left: 5px;
    margin-top: 6px;
}

.textInput {
    width: 50%;
    border-radius: 8px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #d9d4d4;
    font-size: 16px;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif !important;
}

.twoInputs {
    margin-top: 20;
    display: flex;
    width: 50%;
    justify-content: space-between;
}

.halfInput {
    border-radius: 8px;
    width: 49%;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #d9d4d4;
    font-size: 16px;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif !important;
}

.errorMsg {
    color: red;
    margin-left: 10;
}