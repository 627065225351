/* Customizable Area Start */
.modalHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 0px 20px 20px 20px;
}

.modalDp {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.modalPicTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalEventTitle {
  margin-left: 15px;
  font-weight: 600;
  font-family: 'Rene_Bieder_Milliard_Book', serif;
  font-size: 18px;
}

.modalSearchSettingIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalSearchContainer {
  cursor: pointer;
}

._search_logo {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.modalFooterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  bottom: 10;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 90.1%;
  bottom: 2%;
  margin-top: 5px;
}

.modalFooterInput {
  background: #f7f7f7;
  border-radius: 10px;
  height: 52px;
  padding: 10px 10px;
  width: 100%;
  max-width: 88%;
  margin-bottom: 20px;
  position: relative;
}

.modalSendContainer {
  background: #009be2;
  border-radius: 26px;
  align-items: center;
  margin-bottom: 20px;
}
.disableSendContainer {
  background: #f7f7f7;
  border-radius: 26px;
  align-items: center;
  margin-bottom: 20px;
}

.modalIcon {
  height: 26px;
}

.modalContent {
  padding: 20px;
}

.alignLeftCss {
  text-align: left;
  background: #f7f7f7;
  border-radius: 0px 12px 12px 12px;
  width: fit-content;
  padding: 0px 10px;
}

.alignLeftCss>div>div:nth-child(1){
  margin-bottom: 0px;
}

.alignLeftCss>div>div:nth-child(2){
  margin-top: -5px;
}

.alignRightCss {
  text-align: right;
  background: #009BE2;
  border-radius: 12px 12px 0px 12px;
  width: fit-content;
  padding: inherit;
}

.alignRightCss>div:nth-child(1)>div{
  margin-bottom: 0px;
}

.alignRightCss>div:nth-child(2)>div{
  margin-top: 0px;
}

.SearchBarDiv {
  display: flex;
  align-items: center;
  padding-left: 35px;
}

.textSearch {
  width: 75%;
}

.textSearch>div:before{
  border-bottom: 1px solid #c3c3c3d6;
}

.cancelButton {
  color: #009be2
}

@media only screen and (max-width: 600px) {
  .modalFooterContainer {
    padding: 0;
  }
}

.dateIssue{
  text-align: center;
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
  color: #8080808a;
}

.emojiPicker {
  position: absolute;
  width: 94%;
  top: 475px;
}
/* Customizable Area End */
