.modalWrapper {
    background-color: #8d8d8db0;
}

.modalBox {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: #fff;
    max-width: 550px;
    min-width: 425px;
    min-height: 250px;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
}

.modalBox h6 {
    font-family: "Rene_Bieder_Milliard_Book";
    font-weight: bold;
}

.modalBox p {
    font-family: "Rene_Bieder_Milliard_Book" !important;
    font-weight: 400;
    margin-bottom: 20px;
}

.modalBox .modalBtn {
    width: 50%;
    color: #fff !important;
    background-color: #009be2 !important;
    margin: 0 auto;
}

.modalBox .checkSubmit {
    color: #009be2;
    border: 2px solid #009be2;
    border-radius: 50%;
    padding: 10px;
    height: 55px;
    width: 55px;
    font-size: 25px;
    margin-bottom: 20px;
}

.modalBox .error{
    color: red;
    padding: 5px;
    height: 65px;
    width: 65px;
    font-size: 25px;
    margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
    .modalBox {
        min-width: 310px;
    }
}