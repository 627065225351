.mainDiv {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 12px;
  border: 1px dashed #ffffff;
  background: #ffffff;
  background-blend-mode: normal
}
.backIcon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 23.62px;
    height: 21px;
}
.headerText {
    position: absolute;
    left: 80px;
    top: -10px;
    width: auto;
    height: 29px;
    font-family: "Rene_Bieder_Milliard_Medium",sans-serif;
    font-size: 24px;
    font-weight: 400;
}
.dividerLine {
    opacity: 0.14127605;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    height: 1px;
    border: 1px solid #979797;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}
.orgLogo {
    width: 158px;
    height: 158px;
    border: 0px solid #009be2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgb(0 155 226 / 60%), #076a97);
}
.orgImg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 8px solid #fff;
}
.orgProfileTetx {
    width: auto;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -0.65px;
    margin-bottom: 0;
    max-width: 380px;
}
.orgFollowBtn {
    position: absolute !important;
    left: 800px;
    top: 150px;
    width: 57px;
    height: 24px;
    color: #fefefe;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.5px;
    background-color: #009be2;
}
.locationData{
    width:100%;
}

.location {
    width: 122px;
    height: 21px;
    font-family:"Rene_Bieder_Milliard_Medium", sans-serif;
    color: grey;
    font-size: 17px;
    font-weight: 200;
    letter-spacing: -0.42499998px;
}
.locationImg {
    width: 25px;
    height: 22px;
}

.eventsData {
    width: 31px;
    height: 31px;
    margin: 0 auto;
    color: #009be2;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.65px;
}
.eventsText {
    height: 24px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
}
.followersText {
    height: 24px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.5px;
}

.followersData {
    width: 31px;
    height: 31px;
    margin: 0 auto;
    color: #009be2;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.65px;
}

.statsLine {
    opacity: 0.2;
    width: 100%;
    height: 1px;
    border: 1px solid #979797;
}
.aboutText {
    width: 54px;
    height: 24px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
}
.description {
    opacity: 0.8;
    width: 501px;
    text-align: justify;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    max-height: 100px;
    overflow-y: scroll;
}
.mainBtnDiv {
    display: flex;
    width: 100%;
}
.dividerOneLine {
    position: absolute;
    left: 0;
    opacity: 0.2;
    width: 100%;
    height: 1px;
    border: 0px solid #979797;
    background-color: #979797;
    margin: 0;
}
.mainTabDiv{ 
    position: relative;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
    display: flex;
    width: 94%;
    margin: 0 auto;
}

.tabHeadDivider::after {
    content: '';
    position: absolute;
    border: 2px solid #009be2;
    background-color: #009be2 !important;
    width: 100%;
    bottom: 0;
    z-index: 1;
    border-radius: 4px;
    border-radius: 5px 5px 0 0;
}

.dividerTwoLine {
    position: absolute;
    left: 0;
    opacity: 0.2;
    width: 100%;
    height: 1px;
    border: 1px solid #979797;
    background-color: #979797;
    margin: 0;
    z-index: 9;
}
.messageBtn {
    border-radius: 10px !important;
    border: 1px solid #0091be !important;
    color: #0091be !important;
    width: 100%;
    margin-right: 15px !important;
    text-transform: none !important;
    min-height: 50px;
    font-size: 16px !important;
}
.contactBtn {
    border-radius: 10px !important;
    border: 1px solid #0091be !important;
    color: #0091be !important;
    width: 100%;
    margin-right: 15px !important;
    text-transform: none !important;
    min-height: 50px;
    font-size: 16px !important;
}
.donateBtn {
    border-radius: 10px !important;
    border: 1px solid #009be2 !important;
    background-color: #009be2 !important;
    color: #ffffff !important;
    width: 100%;
    margin-right: 0 !important;
    text-transform: none !important;
    min-height: 50px;
    font-size: 16px !important;
}

.mainBtnDiv button[class*='MuiIconButton-root'] {
    padding:  0 !important;
}
.FollowBtn {
    left: 800;
}

 /* Events Card CSS */

.cardWrapper {
    height: 510px;
    margin: 30 55 5;
    margin-right: 13px;
    overflow: auto;
    padding-right: 37px;
    padding-top: 0px;
}

.notFound {
    height: 186px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardInner {
    position: relative;
    padding: 30px;
    margin-bottom: 20px;
    box-shadow: none !important;
    border-radius: 16px !important;
    border:1px solid #d5d5d5 !important;
    background: #ffffff;
    display :flex;
    justify-content: left;
}
.nonProfitEvent {
    cursor: pointer;
}
.leftCard {
    width: fit-content;
    position: relative;
}
.cardImage {
    width: 313px;
    height:170px;
    border-radius: 16px;
    object-fit: "cover";
}
.rightCard {
    width: calc(100% - 313px);
    position: relative;
}
.activeTimeline {
    background: "#B3E8E2";
    color: "#11A48C";
    position: "absolute";
    font-size: "16px";
    right: 0;
    top: 0;
    border-radius: 0px 16px 0px 16px;
    padding: 5px 30px;
}
.timeline {
    position: absolute;
    color: white;
    font-size: 16px;
    right: 0;
    top: 0;
    border-radius: 10px;
    padding: 5px 30px;
    border: 1px solid #d5d5d5;
}
.favorite {
    z-index: 1;
    position: absolute;
    background-color: white;
    color: black;
    padding: 6px;
    width: 30px !important;
    height: 30px !important;
    right: 12px;
    top: 12px;
    border-radius: 16px;
    font-size: 12px !important;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;
    cursor: pointer;
}
.eventTitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin:0px 0 25px 30px !important;
}
.avatarImgcontainer {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}
.avatarImg {
    width: 23;
    height: 23;
    border-radius: 100%;
    z-index: 1
}
.eventdate_loc {
    font-weight: 400;
    font-size: 14;
    color: #87888f; 
}
.innerInfo {
    padding: 0 0 0 35px;
}
.imageTextWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15;
}
.userInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20;    
}
.btnDbsCarousel {
    color: #fff;
    height: 30px;
    background-color: #F89A01;
    padding: 7px 18px;
    position: absolute;
    bottom: 2px !important;
    border-bottom-left-radius: 13px;
    border-top-right-radius: 13px;
    left: 0;
}
.cancelBtn {
    border-radius: 10px !important;
    border: 1px solid #0091be !important;
    color: #0091be !important;
    width: 40%;
    margin-right: 15px !important;
    text-transform: none !important;
    margin-left: 40px !important;
}
.saveBtn {
    border-radius: 10px !important;
    border: 1px solid #009be2 !important;
    background-color: #009be2 !important;
    color: #ffffff !important;
    width: 40%;
    margin-right: 15px !important;
    text-transform: none !important; 
}
.optText {
    font-family: "Rene_Bieder_Milliard_Book", sans-serif !important;
}

.orgInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.aboutDiv {
    width: 96%;
    margin: 0 0 20px auto;
}

.orgProfile {
    display: flex;
    gap: 35px;
    width: 65%;
    margin: 40px auto 0;
}

.mainPrgSection {
    width: calc(100% - 202px);
}

.userName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardWrapper::-webkit-scrollbar {
    width: 4px;
    display: block !important;
  }
  .cardWrapper::-webkit-scrollbar-track {
    background: #d5d5d5 !important; 
    border-radius: 10px;
  }
  .cardWrapper::-webkit-scrollbar-thumb {
    background: #B2B2B2 !important; 
    border-radius: 10px;
  }
  /* Responsive CSS */


  @media screen and (max-width: 600px) {

    .locationData{
        width:130%;
    }
    .orgLogo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to bottom, rgb(0 155 226 / 60%), #076a97);
    }
    .tabHeadDivider{
        font-weight: 550 !important;
    }
    .orgProfile {
        display: flex;
        gap: 35px;
        padding-top: 10px;
        width: 65%;
        margin: 40px auto 0;
    }

    .orgProfileTetx {
        width: auto;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 15.5px;
        font-weight: 400;
        letter-spacing: -0.65px;
        margin-bottom: -1%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 380px;
    }
    .eventsData {
        width: 31px;
        height: 31px;
        margin: 0 auto;
        color: #009be2;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        letter-spacing: -0.65px;
    }
    .followersData {
        width: 31px;
        height: 31px;
        margin: 0 auto;
        color: #009be2;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        letter-spacing: -0.65px;
    }
    .eventsText {
        height: 24px;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 17px;
        margin-top: -1%;
        font-weight: 400;
        letter-spacing: -0.5px;
    }
    .followersText {
        height: 24px;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 17px;
        margin-top: -1%;

        font-weight: 400;
        text-align: center;
        letter-spacing: -0.5px;
    }
    .aboutText {
        width: 54px;
        height: 24px;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-top: -2%;
    }

    .locationImg {
        width: 18px;
        height: 18px;
        overflow: hidden;
        margin-left: 10px;
        
        margin-top: -9%;
    }
    .description {
        opacity: 0.9;
        margin-top: -4%;
        width: 501px;
        text-align: justify;
        font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        max-height: 100px;
        overflow-y: scroll;
    
    }

    .location {
        margin-top: 1.2%;

            font-family:"Rene_Bieder_Milliard_Medium", sans-serif;
            color: grey;
            white-space: nowrap;
            width: auto;
            overflow: hidden;
            font-size: 15px;
            font-weight: 200;
            letter-spacing: -0.42499998px;
        }


    .mainDiv {
        position: relative;
        left: 0;
        top: 0;
        margin-top: -10%;
        width: 100%;
        height: 140vh;
        border-radius: 12px;
        border: 1px dashed #ffffff;
        background: #ffffff;
        background-blend-mode: normal
      }
    .statsLine1 {
        opacity: 0.2;
        width: 110%;
        margin-left: -5%;

        height: 1px;
        border: 1px solid #979797;
    }
    .statsLine2 {
        opacity: 0.2;
        width: 110%;
        margin-left: -5%;
        margin-bottom: 5%;
        height: 1px;
        border: 1px solid #979797;
    }
    .statsLine {
        opacity: 0.2;
        width: 110%;
        margin-left: -5%;
        height: 1px;
        border: 1px solid #979797;
        margin-top: -2%;
    }
    .orgImg {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 4px solid #fff;
    }

    .orgInfo {
        display: flex;
        align-items: right;
        justify-content: flex-end;
        width: 40%;
        margin-top: -24%;
        padding-top: 0;
        margin-right: 31%;
        
    }


}
@media screen and (max-width: 1400px) {
    .FollowBtn{
        left: 700;
    }
    .timeline {
        right: 25px;
    }
}

@media screen and (max-width: 1200px) {
    .orgProfile {
        display: block;
    }
    .mainPrgSection {
        width: 100%;
    }
}
@media screen and (max-width: 960px) {
    .leftCard {
        max-width: 150px;
        max-height: 150px;
        border-radius: 12px;
    }
    .rightCard {
        width: calc(100% - 150px);
    }
    .cardImage {
        width: 100%;
        height: 100%;
        max-height: 155px;
        max-width: 150px;
    }
}
@media screen and (max-width: 750px) {
    .orgProfile {
        width: 95%;
    }
    .description {
        font-size: 14px;
        line-height: 20px;
        width: auto;
    }
    .mainBtnDiv button {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .aboutDiv {
        width: 100%;
    }
    .mainBtnDiv button img {
        width: 18px !important;
    }
    .orgInfo {
        display: block;
    }
    .leftCard {
        max-width: 120px;
        max-height: 120px;
        border-radius: 12px;
    }
    .rightCard {
        width: calc(100% - 120px);
    }
    .cardImage {
        width: 100%;
        height: 100%;
        max-height: 125px;
        max-width: 120px;
    }
    .rightCardEvents {
        display: flex;
        flex-direction: column;
    }
    .rightCardEvents .timeline {
        order: 1;
        position: relative;
        width: fit-content;
        right: 0;
        margin: 0px 0 10px 20px !important;
        font-size: 14px;
    }
    .rightCardEvents .eventTitle {
        order: 2;
        margin: 0px 0 5px 20px !important;
        font-size: 15px !important
    }
    .btnDbsCarousel {
        left: 0;
        color: #fff;
        bottom: 0 !important;
        height: 25;
        width: 52;
        font-size: smaller;
        padding: 5 14;
        position: absolute;
        background-color: #F89A01;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 13px;
    }
    .cardWrapper {
        padding: 30 15;
    }
    .cardInner {
        padding: 0;
        border: none !important;
    }
    .userInfo,
    .imageTextWrapper {
        margin-bottom: 8;
    }
    .mainBtnDiv button[class*='MuiIconButton-root'] {
        margin-right: 0 !important;
    }
    .eventdate_loc {
        font-size: 12px !important;
    }
    .avatarImgcontainer {
        margin-right: 8px !important;
    }
    .avatarImg {
        width: 18px;
        height: 18px
    }
    .innerInfo {
        padding: 0 0 0 20px;
    }
}

.event_main_img{
    height:170px;
    width: 326px;
    border-radius: 15px;
    object-fit: cover;
}

.orgProfile1 {
    display: flex;
    gap: 35px;
    width: 65%;
    margin: 40px 15% 0;
}