/* Customizable Area start */
.mainDiv {
  background-color: #fff;
  margin-top: 30px;
  border-radius: 15px;
} 
.headingDiv {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading h3,h6 {
  font-family: "Rene_Bieder_Milliard_SemiBold";
  font-size: 20px;
  font-weight: 400;
}
.tableHead tr {
  border-top: 1px solid rgba(224, 224, 224, 1);
}
.tableHead tr th{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-family: 'Rene_Bieder_Milliard_SemiBold', sans-serif !important;
}
.tableCell tr td {
  border-bottom: none;
  cursor: pointer;
}
.modalBox {
  position: absolute;
  height: 480px;
  width: 648px;
  background-color: #fff;
  top: 96px;
  left: 303px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 12%);
  border-radius: 10px;
}             

/* Customizable Area End */
