/* Customizable Area Start */
.mainContainer{
    margin: 54px auto;
    padding: 40px 40px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 1px 6px 30px 1px #c5c5c4;
}
.landingPagelogo {
    text-align: center;
    padding: 0px 100px;
}
.logoImg {
    height: 300px;
}
.buttonContainer{
    padding-top: 80px;
}
.landingPageDarkButton button{
    background-color: #359ce2;
    color: #ffff;
    width: 100%;
    text-transform: none;
    border-radius: 15px;
    transition: background-color 1s, color 2s ease-out;
    margin-bottom: 15px;
    height: 60px;
    font-size: 20px !important;
}
.landingPageDarkButton button:hover{
    background-color: #1e85ca;
}
.landingPageLightButton button{
    border: 1px solid #b8b4b4a6;
    color: #359ce2;
    width: 100%;
    text-transform: none;
    border-radius: 15px;
    transition: background-color 1s, color 2s ease-out;
    margin-bottom: 15px;
    height: 60px;
    font-size: 20px !important;
}
@media only screen and (max-width: 600px) {
    .mainContainer{
        margin: 0;
        padding: 0;
        border-radius: 25px;
        background-color: #fff;
        box-shadow: none;
    }
    .landingPagelogo{
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
    .MuiGrid-container{
        display: block !important;
    }
}


/* Customizable Area End */