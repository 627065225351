.Mobwrapper {
	padding: 0 15px;
}

.titleHeading {
	font-size: 18px;
	line-height: normal;
	color: #000000;
	margin: 0;
	padding: 5px 0;
	position: relative;
	background: #fff;
	z-index: 2;
	border-bottom: 1px solid rgba(151, 151, 151, 0.14);
}

.titleHeading:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	left: 0;
	z-index: 1;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);

	bottom: 0;
}

.titleHeading img {
	width: 24px;
	height: 24px;
	transform: matrix(-1, 0, 0, 1, 0, 0);

}

.filterBox {
	position: relative;
}

.filterBox .srchicons {
	position: absolute;
	width: 24px;
	height: 24px;
	left: 16px;
	bottom: 20px;
}

.listtitle span {
	max-width: 140px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.bigavatar {
	width: 76px !important;
	height: 76px !important;
}

.smallavatar div {
	width: 20px;
	height: 20px;
}

.btnDonate {
	border-radius: 8px !important;
	width: 76px;
	height: 22px;
	text-transform: capitalize !important;
	margin-bottom: 20px !important;
}

.eventCard {
	width: 100%;
	display: flex;
	margin-bottom: 15px;
	position: relative;
}

.eventHeader {
	font-size: 12px !important;
	font-weight: 600 !important;
	font-family: 'Rene_Bieder_Milliard_Book', sans-serif !important;
}

.ellipsis {
	max-width: 140px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.eventType {
	width: 76px;
	height: 22px;
	border-radius: 8px;
	margin-left: auto;
	text-align: center;
	color: #ffffff;
	font-family: 'Rene_Bieder_Milliard_Book', sans-serif !important;
	margin-right: 10px;
	font-size: 12px;
	align-items: center;
    justify-content: center;
    display: flex;
}

.orgName {
	font-family: 'Rene_Bieder_Milliard_Book', sans-serif !important;
	font-size: 10px;
	font-weight: 400;
}

.imgWithText {
	display: flex;
	align-items: center;
	margin-top: 8px;
}

.avatars {
	position: absolute;
	right: 10;
	bottom: 20;
	float: right;
}