/* My pledge  Start*/
.myPledge{
  margin-top: 20px;
  margin-bottom: 20px
}
.myPledgeTab {
  min-width: 50% !important;
  text-transform: none !important;
  box-shadow: 0 0 8px rgb(0 0 0 / 12%);
}
.myPledgeTab:active{
  box-shadow: 0 0 8px rgb(0 0 0 / 12%);
  color: #009be2;
}
/* My pledge  End*/
.userprofileMain{
    margin-left: 0;
  }
  .backIconWeb{
      display: flex;
      align-items: center;
    }

.modalBox{
   height: auto;
   background-color: white;
   position: relative;
   top: 28%;
   text-align: center;
   left: 45%;
   width: 20%;
   border-radius: 15px;
}

.warningIcon {
  margin: 40px 0 20px 0;
}

.userprofileMain .userprofileTabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px 
}
.userprofileMain .userprofileTabs button {
  text-transform: capitalize;
  text-align: center;
  width: 50%;
  font-weight: bold;
  color: #B2B2B2;
  border-right: none;
}

.userprofileMain .userprofileTabs .userprofileTabActive {
  box-shadow: 0 0 8px rgb(0 0 0 / 12%);
  color: #009be2;
}

  @media only screen and (max-width: 600px) {
      .userprofileMain{
          margin-left:0px;
        }
        /* .backIconWeb{
          display: none;
        } */
    }
  
    /* User Edit Profile   nandini*/
  .profileIconBox{
    display: flex;
    justify-content: center;
  }
  .profileIcon{
    height: 80px !important;
    width: 80px !important;
    margin-top: 60px;
    margin-bottom: 25px;
  }
  .label{
    color: #359ce2
  }
  .editInputField{
    border: 1px solid #c2c2c2;
    margin-top: 7px;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    box-shadow: 0 0 8px rgb(0 0 0 / 12%);
  }
  .bioInput{
    border: 1px solid #c2c2c2;
    margin-top: 7px;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    height: 90px;
    padding: 0 15px;
    box-shadow: 0 0 8px rgb(0 0 0 / 12%);
  }
  .edit{
    position: absolute;
    right: 30px;
    top: 98%;
  }
  
  
  /* userprofile main shivani  */
  
  .profileContainer {
    flex: 1;
    margin-top: 30px;
  }
  .userDetail{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .userCompanyLogo{
    /* height: 50px; */
    width: 100px;
  }
  .userProfileContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  
  }
  
  .profilePicContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
  
  }
  
  .personalInfo{
    margin-left: 20px;
  }
  
  .profilePicimg {
    width: 50%;
    height: 50%;
  }
  
  .profilePicimg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #009be2;
    padding: 7px;
  
  }
  
  .profilebio{
    margin-top: 50px;
  }
  
  @media only screen and (max-width: 600px) {
  
  .profilePicimg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #009be2;
    padding: 7px;
  
  }
  }
  
  .connectionsDetails{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
  }
  
  .innerconnection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  
  
  .bottomTabs{
    display: flex;
    flex-direction: "row";
    /* justify-content: center;
    align-items: center; */
}

.tabItems{
  display: flex;
  flex-direction: "row";
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width : 600px) {
   .btnGrooup {
     margin-top: auto;
   }
}