.published-need.projectOutcome {
  width: 50%;
  margin-top: 0;
}

.published-need.projectOutcome.new {
  width: 100%;
}

.published-need .title {
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
  font-weight: 600;
}

.published-need .text {
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need .picker-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.published-need .textField {
  padding: 0;
  border-radius: 12;
  border-color: "none !important";
  height: 42;
  box-shadow: '0px 0px 8px rgba(212, 212, 212, 0.5)';
  border: "none !important";
}

.published-need .textField::-webkit-calendar-picker-indicator {
  display: "none";
}

.published-need .antSwitch-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.published-need .time-picker .MuiOutlinedInput-root {
  height: 50px;
  border-radius: 10px;
  background-color: #FFF;
}

.published-need .time-picker .MuiOutlinedInput-root input::placeholder {
  font-size: 16px;
  color: #898989;
}

.published-need .time-picker .MuiOutlinedInput-notchedOutline {
  border: 1px solid #D5D5D5 !important;
}

.published-need .time-picker .MuiOutlinedInput-inputAdornedEnd {
  padding-left: 25px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need .text-danger {
  margin-top: 2px;
  margin-left: 8px;
  font-size: 14px;
  color: #f94b4b;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

.published-need .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.published-need .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid rgba(151, 151, 151, 0.43);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.published-need .switch .slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 1px;
  background-color: #87888f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.published-need .switch input:checked+.slider {
  border: 1px solid #2196f3;
  background-color: #fff;
}

.published-need .switch input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.published-need .switch input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  background-color: #2196f3;
}

.published-need .react-calendar__tile:disabled {
  color: #c5b5b5 !important;
}

.published-need .switch .slider.round {
  border-radius: 34px;
}

.published-need .switch .slider.round:before {
  border-radius: 50%;
  left: 2px;
}

.published-need .react-select__control {
  border: 1px solid #d6d6d6 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  min-height: 50px !important;
}


.published-need.projectOutcome .react-select__control {
  border: 1px solid #d9d4d4 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  min-height: 56px !important;
}
.published-need.projectOutcome .react-select__indicators {
  padding-right: 7px;
}
.published-need.projectOutcome .react-select__dropdown-indicator {
  display: none;
}

.published-need .react-select__control:hover {
  border: 1px solid #d6d6d6 !important;
}

.published-need .react-select__clear-indicator {
  padding: 0px !important;
  margin-right: 35px;
}
.published-need .react-select__dropdown-indicator {
  display: none;
}

.published-need .react-select__indicator-separator {
  display: none;
}

.published-need .react-select__input {
  padding-left: 15px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need .react-select__placeholder {
  padding-left: 25px;
  color: #d8caca !important;
  font-size: 16px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need.projectOutcome .react-select__placeholder {
  color: #b1b1b1 !important;
  font-size: 14px;
}

.published-need .react-select__value-container {
  padding: 0px !important;
}

.published-need .react-select__menu {
  padding: 0px 5px;
  box-shadow: none !important;
  border: 1px solid #d6d6d6;
  border-radius: 10px !important;
  margin: 5px 0px !important;
}

.published-need .react-select__option {
  min-height: 50px !important;
  border-radius: 10px;
  margin-top: 5px;
  padding-left: 15px;
  display: flex !important;
  align-items: center;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need .react-select__menu-notice {
  min-height: 40px !important;
  display: flex !important;
  align-items: center;
}

.published-need .react-select__option .selected {
  padding-left: 15px;
  background-color: #eff6ff !important;
}

.published-need .react-select__input-container {
  padding-left: 25px;
  font-size: 16px;
}

.published-need.projectOutcome .react-select__input-container, .published-need.projectOutcome .react-select__placeholder{
  padding-left: 15px;
  font-size: 14px;
}

.published-need .react-select__single-value {
  width: 100%;
  padding-left: 25px;
  font-size: 16px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need.projectOutcome .react-select__single-value {
  width: 100%;
  padding-left: 15px;
  font-size: 16px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.published-need .react-select__menu-list::-webkit-scrollbar {
  width: 10px;
}

.published-need .react-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.published-need .react-select__menu-list::-webkit-scrollbar-thumb {
  background: rgb(182, 186, 189);
  border-radius: 10px;
}

.published-need .checked {
  width: 20px;
  height: 20px;
  border: 1px solid #e3e3e3;
  position: relative;
  border-radius: 50%;
}

.published-need .checked .blue {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 4px;
  right: 4px;
  background-color: #009be2;
  border-radius: 50%;
}

.published-need .unchecked {
  width: 20px;
  height: 20px;
  border: 1px solid #e3e3e3;
  position: relative;
  border-radius: 50%;
}

.proximitySearchMain {
  width: 100%;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  position: absolute
}

.hideProximitySearchMain {
  z-index: 4;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  width: 100%;
}

.modalInProximitySearchInput {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
}

.published-need .card {
  background-color: rgba(255, 255, 255, 0.55);
  border-radius: 20px;
  margin: auto;
  width: 80%;
}

.published-need .card-content {
  padding: 30px 44px
}

.published-need .react-calendar__tile--hasActive {
  background: white;
  color: black;
}

.published-need .react-calendar__tile--active {
  background-color: rgb(0, 155, 226) !important;
  border-radius: 50%;
}

.published-need .react-calendar__tile:enabled:hover,
.published-need .react-calendar__tile:enabled:focus {
  border-radius: 50%;
}

.published-need .react-calendar__tile {
  height: 39px !important;
}

.published-need .first-item-data {
  position: relative;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  color: #fff !important;
}

.published-need .first-item-data > abbr {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  transform: translateY(-50%) translateX(-50%);
  padding: 5px;
  background-color: #009BDF !important;
  border-radius: 50%; 
}

@media screen and (max-width: 576px) {
  .published-need .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .published-need .card {
    width: 100%;
  }

  .published-need .card-content {
    padding: 0px;
  }
}