/* Customizable Area Start */
/* Form and app logo css start */
.mainContainer {
  justify-content: center;
  align-items: center;
  display: grid !important;
  background-color: white;
  /* height: 100vh; */
}

.formContainer {
  box-shadow: 1px 6px 30px 1px #c5c5c4;
  background-color: white;
  border-radius: 15px;
  width: 30rem;
  align-items: center;
  padding: 2.5rem;
  margin: 50px 0;
}

.appLogoContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 2rem;
}

.appLogo {
  height: 7rem;
  width: 7rem;
}

/* Form and app logo css end */

.titleText {
  color: #009be2;
  text-align: left;
  line-height: 30px;
  margin: 1rem 0 !important;
}

.inputFieldText {
  color: #000000;
  line-height: 22px !important;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.inputField {
  height: 56px !important;
  padding: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 2px solid lightgray;
  border-radius: 12px;
  font-size: 30px;
}

.innerInputText input {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
}

.footerBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-top: 150px !important;
}

.errorMsg .errors {
  text-align: left;
  font-size: 12px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Book";
  color: #ff0000;
}

/* Mobile UI media query */
@media only screen and (max-width: 600px) {
  .mainContainer {
    justify-content: inherit;
    height: auto;
  }

  .formContainer {
    width: 100%;
    padding: 1.25rem;
    padding: 1.25rem 0;
  }
}
/* Customizable Area End */
