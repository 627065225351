/* New CSS for Admin Registration Start */

.mainRegisterContainer {
    margin-top: 20px;
  }
  .registrationFormHead {
    color: #009be2;
    /* font-weight: 700 !important;
    font-size: 26px !important; */
    padding: 10px 0px;
    text-align: center;
  }
  .labelInputBox {
    margin-bottom: 20px;
  }
  .labelForInput {
    font-family: "Rene_Bieder_Milliard_Book",sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.373333px;
    color: #0e0d0e;
  }
  .inputField {
    margin-top: 10px;
  }
  .inputField input {
    height: 15px;
    font-family: "Rene_Bieder_Milliard_Book",sans-serif;
    font-size: 16px;
    letter-spacing: 0.4px;
  }
  .inputField input:focus {
    outline: none;
  }
  .organisationTypeDropdown select {
    width: 100%;
    padding: 18.5px 14px;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 16px;
    font-family: "Rene_Bieder_Milliard_Medium",sans-serif;
    background-color: #fff;
    color: #534f4f87;
  }
  
  .searchfieldInputStyle input {
    width: 100%;
    padding: 18.5px 14px;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 16px;
    font-family: "Rene_Bieder_Milliard_Medium",sans-serif;
    background-color: #fff;
    color: #534f4f87;
  }
  
  .fieldMandatoryNP p {
    /* color: #656469; */
    margin-bottom: 10px;
    font-size: 14px;
  }
  .fieldMandatoryNP span {
    color: #fd1d1d;
  }
  
  .darkButtonNP {
    padding: 0 30px 0 30px;
  }
  
  .darkButtonNP button {
    background-color: #009be2 !important;
    color: #ffff;
    width: 100%;
  
    text-transform: none;
    border-radius: 15px;
    margin-bottom: 15px;
    height: 60px;
    /* font-weight: 700;
    font-size: 18px !important; */
  }
  
  .darkButtonDisabledNP {
    padding: 0 30px 5px 30px;
  }
  
  .darkButtonDisabledNP button {
    background-color: #009be2 !important;
    color: #ffff !important;
    width: 100%;
    text-transform: none;
    border-radius: 15px;
    margin-bottom: 15px;
    opacity: 0.6;
    height: 60px;
    /* font-weight: 700; */
    /* font-size: 18px !important; */
  }
  
  .darkButton button:hover {
    background-color: #1c77b3 !important;
    color: #fff;
  }
  .dropdown {
    margin-top: 10px;
    position: relative;
  }
  
  /* .dropdown div div:last-child span {
    background-color: transparent;
  } */
  /* .organisationTypeDropdown{
    
  } */
  
  .organisationssearchreletiveenv {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.8);
    border-radius: 16px;
    z-index: 2;
    border-width: 1px;
    box-sizing: border-box;
    outline: 0px !important;
    -webkit-box-align: center;
    cursor: default;
    -webkit-box-pack: justify;
    transition: all 100ms ease 0s;
    /* overflow-y: auto; */
  }
  
  .organisationdropdownInput {
    height: 56px;
    border-radius: 10px;
    border: 1px solid #c7c7c7;
  }
  
  .activeorganisationdropdownInput {
    /* background: #FFFFFF; */
    box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
    border-radius: 10px;
    border: 2px solid #009be2;
    height: 54px;
  }
  
  .orgsearchfilterEnv {
    /* background: #FFFFFF; */
    box-shadow: 0px 0px 8px #c6c6c6;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    position: absolute;
    width: 100%;
  }
  
  .registerTextField {
    border-radius: 10px !important;
    height: 56px !important;
  }
  
  .fieldMandatoryNPSentence {
    /* font-weight: 400; */
    /* font-size: 14px; */
    line-height: 16px;
    text-align: left;
    color: #353848;
    /* font-family: Rene_Bieder_Milliard_Medium; */
  }
  
  .footerBtn {
    width: 100%;
    height: 56px;
    background-color: #009be2 !important;
    color: white !important;
    border-radius: 10px !important;
    margin: 30px !important;
    font-size: 18px !important;
    font-family: "Rene_Bieder_Milliard_Medium",sans-serif !important;
    font-weight: 500 !important;
  }
  /* New CSS for Admin Registration End */
  