@font-face {
  font-family: 'Rene-Bieder-Milliard-Black-Italic';
  src: url('../../components/assets/fonts/Rene-Bieder-Milliard-Black-Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Black';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Black.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Bold';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Bold.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Book Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Book Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Book';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Book.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_ExtraBold Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard ExtraBold Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_ExtraLight Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard ExtraLight Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_ExtraLight';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard ExtraLight.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Hairline Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Hairline Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Hairline';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Hairline.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Heavy Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Heavy Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Heavy';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Heavy.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Light Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Light Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Light';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Light.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Medium Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Medium Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Medium';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Medium.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_SemiBold Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard SemiBold Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_SemiBold';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard SemiBold.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Thin Italic';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Thin Italic.otf');
}

@font-face {
  font-family: 'Rene_Bieder_Milliard_Thin';
  src: url('../../components/assets/fonts/Rene Bieder  Milliard Thin.otf');
}

.backgroundimg {
  /* background-image: url('../../components/assets/images/bgimg.svg'); */
  background-color: #F2F2F2;
  background-size: cover;
  background-position: inherit;
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  display: none !important;
}

.scrollbars1::-webkit-scrollbar {
  display: block !important;
}


@media only screen and (max-width: 600px) {
  .backgroundimg {
    background-color: white;
    background-image: none;
  }
}

.react-calendar__tile--now {
  background-color: white !important;
}

.react-calendar__tile--now :hover {
  background-color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000;
}

.react-daterange-picker__wrapper {
  display: none;
}

.react-calendar {
  border: none !important;
  border-radius: 15px;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.247);
  padding: 1.4rem 1.4rem;
  z-index: 50;
}

.react-calendar__tile--active {
  background-color: #9BD9F4;
  color: #000 !important;


}

.address-location .react-select__control {
  border: 1px solid #f9eaea !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  min-height: 56px !important;
}
.address-location.npSignUp .react-select__control,
.address-location.personal-info .react-select__control {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.119346) !important;
}
.address-location.user-profile .react-select__control {
  min-height: 50px !important;
}
.address-location.np-profile .react-select__control,
.address-location.np-profile .react-select__control:hover {
  border: 1px solid #d1cece !important;
}
.address-location.user-profile .react-select__control,
.address-location.user-profile .react-select__control:hover {
  border: 1px solid #b6b4b4 !important;
}
.address-location.business .react-select__control {
  border: 1px solid #d1cece !important;
}
.address-location .react-select__control:hover {
  border: 2.5px solid #bebebe !important;
}
.address-location.business .react-select__control:hover {
  border: 2.5px solid #d1cece !important;
}
.address-location.personal-info .react-select__control:hover {
  border: 1px solid #d1cece !important;
}
.address-location .react-select__clear-indicator {
  padding: 0px !important;
  margin-right: 10px;
}
.address-location .react-select__dropdown-indicator {
  display: none;
}
.address-location .react-select__indicator-separator {
  display: none;
}
.address-location .react-select__input {
  padding-left: 15px;
  font-family: Rene_Bieder_Milliard_Medium, Serif, Sans-serif !important;
}
.address-location.business .react-select__input {
  padding-left: 15px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}
.address-location .react-select__placeholder {
  padding-left: 10px;
  color: #b9b5b5 !important;
  font-size: 14px;
  font-family: Rene_Bieder_Milliard_Medium, Serif, Sans-serif !important;
}
.address-location.personal-info .react-select__placeholder {
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}
.address-location .react-select__value-container {
  padding: 0px !important;
}
.address-location .react-select__menu {
  padding: 0px 5px;
  box-shadow: none !important;
  border: 1px solid #d6d6d6;
  border-radius: 10px !important;
  margin: 5px 0px !important;
}
.address-location .react-select__option {
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 15px;
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}
.address-location .react-select__menu-notice {
  min-height: 40px !important;
  display: flex !important;
  align-items: center;
}
.address-location .react-select__input-container {
  padding-left: 10px;
  font-size: 16px;
  font-family: Rene_Bieder_Milliard_Medium, Serif, Sans-serif !important;
}
.address-location .react-select__single-value {
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
  font-family: Rene_Bieder_Milliard_Medium, Serif, Sans-serif !important;
}
.address-location.user-profile .react-select__single-value {
  font-size: 13px;
}
.address-location.business .react-select__single-value {
  font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}
.address-location .react-select__menu-list::-webkit-scrollbar {
  width: 10px;
}
.address-location .react-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.address-location .react-select__menu-list::-webkit-scrollbar-thumb {
  background: rgb(182, 186, 189);
  border-radius: 10px;
}

.first-item-data {
  position: relative;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  color: #fff !important;
}


.first-item-data>abbr {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 25px;
  transform: translateY(-50%) translateX(-50%);
  padding: 5px;
  background-color: #009BDF !important;
  border-radius: 50%;

}

.last-item-data {
  position: relative;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  color: #fff !important;
}


.last-item-data>abbr {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 25px;
  transform: translateY(-50%) translateX(-50%);
  padding: 5px;
  background-color: #009BDF !important;
  border-radius: 50%;
}


.checkboxCustom {
  padding: 5px !important;
}


.react-calendar__navigation {
  align-items: center;
  justify-content: space-around;
  padding: 1rem 2rem 1rem 2rem;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;

}

.react-calendar__navigation button {
  min-width: auto;

}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  border: 1px solid #000 !important;
  font-size: 1.2rem;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation__next-button {
  border: 1px solid #000 !important;
  font-size: 1.2rem;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__month-view__weekdays__weekday {
  text-decoration: none !important;
  color: #009BDF;
  font-size: 0px;
}


.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;

}



.react-calendar__month-view__weekdays__weekday::first-letter {
  font-size: 14px;
}

.react-calendar__tile:disabled {
  background-color: #fff;
}

.AvatarGroupMyNeed :last-child {
  height: 32px;
  width: 32px;
  background: #009be2;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.AvatarGroupMyNeed div {
  height: 32px;
  width: 32px;
  background: #009be2;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.stickyHeaderTable thead th{
  border-bottom: 2px solid #e2e2e2 !important;
  background-color: #fff;
}

.fundSlider {
  margin-top: 10px !important;
}

.fundSlider .MuiSlider-root {
  cursor: default;
}

.fundSlider .MuiSlider-rail {
  border: 1px solid #e8e8e8;
  border-radius: 9px;
  height: 10px !important;
  opacity: 1;
  background: linear-gradient(90deg, #eeeeee 0%, #30ade6 42.62%, #009be2 100%) !important;
  cursor: default;
}

.donateTabs div > div {
  flex-wrap: wrap;
}
.fundSlider .MuiSlider-track {
  background: transparent;
  border-radius: 9px;
  height: 10px !important;  
  cursor: default;
}

.fundSlider .MuiSlider-thumb {
  margin-top: 12px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top: 0;
  border-bottom: 14px solid #009be2;
  cursor: default;
}

.fundSlider .MuiSlider-thumb:focus,
.fundSlider .MuiSlider-thumb:hover,
.fundSlider .MuiSlider-thumb:active {
  box-shadow: none !important;
}

.fundSlider .MuiSlider-valueLabel  span > span {
  font-weight: 400;
  font-size: 15px;
  line-height:  19px;
  letter-spacing: -0.4px;
  color: #009be2;
}
.fundSlider .MuiSlider-valueLabel span  {
  background: transparent;
  margin-top: 30px;
  margin-left: -12px;
}

.fundSlider .MuiSlider-valueLabel{
  top: -25;
}


.updatesSection ::-webkit-scrollbar {
  width: 8px;
  display: block !important;
}

/* Track */
.updatesSection ::-webkit-scrollbar-track {
  background: #f1f1f1 !important; 
}
 
/* Handle */
.updatesSection ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background: #009be2 !important;
}

/* Handle on hover */
.updatesSection ::-webkit-scrollbar-thumb:hover {
  background: #159ddb !important; 
}

.orgDialogWrapper > div:nth-child(3) div {
  border-radius: 16px;
  box-shadow: none;
}

.activityLogBtns .MuiButtonGroup-groupedHorizontal:not(:last-child).activeEventTabs{
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.activityLogBtns .MuiButtonGroup-groupedHorizontal:last-child.activeEventTabs{
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
}
.activityLogBtns .MuiButtonGroup-groupedHorizontal:nth-child(2).activeEventTabs{
  border-radius: 0px;
}
.activityLogBtns .activeEventTabs{
  color: #009be2 !important;
  border: 1px solid #009be2 !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 8px rgba(0, 61, 89, 0.2) !important;
}
.activityLogBtns .inactiveTab {
  border-bottom: 1px solid rgba(0, 61, 89, 0.2) !important;
}

.activitylog-table table tr th, .activitylog-table table tr td{
  padding-left: 25px;
}

.activitylog-table ::-webkit-scrollbar {
  display: block !important;
  width: 7px;
}
.activitylog-table ::-webkit-scrollbar-track {
  margin-top: 70px !important; 
}
.commentModal .MuiPaper-root {
  border-radius: 16px;
  width: 420px;
  box-shadow: none;
}

.react-daterange-picker__calendar {
  z-index: 100;
  margin-top: 80px;
}

.activityCalendar .react-daterange-picker__calendar {
  margin-top: 40px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #87888f;
  border: 1px solid rgba(151, 151, 151, 0.43);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked+.slider {
  border: 1px solid #009BE2;
  background-color: #009BE2;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  background-color: #fff;
  left: 7px;
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}