/*  Customizable Area Start */
.swipe-list-pledges {
    position: relative;
    border: 1px solid #ddd;
  }
  
  .box-action-pledges {
    position: absolute;
    right: 25px !important;
    margin-top: 23px;
    top: 100px !important;
    display: flex;
    flex-direction: column;
    width: 60px;
    align-items: center;
    text-align: center;
  }
  
  .item-group {
    overflow: hidden;
    background: #ccc;
  }
  
  .item {
    position: relative;
    z-index: 2;
    transition: all 0.1s linear;
  }
  
.modal-pledges {
  background-color: #fff;
  color: #000;
  height: 30%;
  margin: 0 20px;
  border-radius: 10px;
  display: flex;
  margin-top: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modalBlueButton {
  background-color: #359ce2 !important;
  color: #fff !important;
  font-size: 16px !important;
  height: 49px;
  border-radius: 15px !important;
  width: 85%;
  cursor: pointer;
}
.modalTransparentButton{
  color: #000 !important;
  font-size: 16px !important;
  height: 49px;
  border: 1px solid #7e7e7e !important;
  border-radius: 15px !important;
  width: 85%;
  margin-top: 15px !important;
  cursor: pointer;
  margin-bottom: 15px !important;
}

 /* Customizable Area Start */