 /* Customizable Area Start */
 .r1Wrapper {
    background: #fff;
    padding: 20px 40px;
}

.mainOrgWrapper {
    width: 95%;
    margin: 0px auto;
}

.orgProfile {
    display: flex;
    gap: 35px;
    margin: 40px auto 20px;
}

.orgLogo {
    width: 158px;
    height: 158px;
    border: 0px solid #009be2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgb(0 155 226 / 60%), #076a97);
}

.orgImg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 8px solid #fff;
}

.mainPrgSection {
    width: calc(100% - 202px);
}

.orgInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.donateBtnContainer {
    width: 25%;
    padding: 10px;
}

.orgProfileTetx {
    width: auto;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 26px;
    font-weight: 400;
    margin: 0;
}

.location {
    height: 21px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 17px;
    font-weight: 400;
    padding-right: 10px;
}

.locationImg {
    width: 25px;
    height: 22px;
}


.statsLine {
    opacity: 0.2;
    width: 100%;
    height: 1px;
    border: 1px solid #979797;
}

.aboutDiv {
    width: 90%;
    margin: 0 auto;
}

.aboutText {
    width: 54px;
    height: 24px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
}

.description {
    opacity: 0.8;
    text-align: justify;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    max-height: 100px;
    overflow-y: scroll;
}

.dividerOneLine {
    position: absolute;
    left: 0;
    opacity: 0.2;
    width: 100%;
    height: 1px;
    border: 0px solid #979797;
    background-color: #979797;
    margin: 0;
}

.mainBtnDiv {
    display: flex;
    margin: 20px 0;
}

.disabledBtn {
    border-radius: 5px !important;
    border: 1px solid #ebebeb !important;
    color: #87888f !important;
    margin-right: 15px !important;
    text-transform: none !important;
    min-height: 50px;
    font-size: 16px !important;
    background: #ebebeb !important;
}

.normalBtn {
    border-radius: 5px !important;
    border: 1px solid #0091be !important;
    margin-right: 15px !important;
    text-transform: none !important;
    min-height: 50px;
    font-size: 16px !important;
}

.activeBtn {
    border: 1px solid #009be2 !important;
    background-color: #009be2 !important;
    color: #ffffff !important;
}

.currentPoints,
.donatedPoints {
    font-size: 16px;
    margin: 20px auto;
}

.currentPoints {
    margin: 5px 0;
}

.donatedPoints span,
.loaderColor {
    color: #009be2 !important;
}


.currentPoints span {
    font-size: 18px;
    color: #0091be;
    font-weight: 600;
}

.donateBtnWrap {
    display: flex;
    justify-content: center;
}

.donateNowBtn {
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #009be2 !important;
    border-radius: 5px !important;
    padding: 10px 60px !important;
    margin: 50px auto !important;
}

.goToProfile {
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #009be2 !important;
    border-radius: 5px !important;
    padding: 10px 60px !important;
}

.dialogWrapper {
    padding: 50px;
    border-radius: 12px !important;
}

.dialogWrapperInner {
    text-align: center;
    margin-bottom: 20px;
}

.backIconWeb {
    display: flex;
    align-items: center;
}

.toolBar {
    padding: 0 !important;
    margin: 20px 0;
}

.dialogWrap {
    padding: 20px 30px;
    border-radius: 16px !important;
}

.donateOrg {
    width: 420px;
}

.pointsTable {
    padding: 0px 0 20px;
}
.activityTable{
   padding: 30px 25px 20px;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexAlignCenter {
    display: flex;
    align-items: center;
}

.tableWrapper {
    overflow: auto;
}

.backTitle {
    font-size: 20px;
    color: #000;
    margin-left: 30px
}

.organisationName {
    color: #424242;
    font-size: 16px !important;
}

.organisationDescription {
    color: #9a9a9a;
    font-size: 16px !important;
    padding-top: 5px;
}


.organisationLogo {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 20px;
}

.userName {
    font-size: 18px !important;
    color: #1e1e1e;
    font-weight: 500 !important;
}

.modalDialog {
    margin-bottom: 20px !important;
}

.nonProfit {
    margin-top: 20px !important;
}

.message {
    font-size: 20px !important;
}

.searchboxStyle {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #dee1ea;
    font-size: 30px !important;
}

.noRecord {
    font-size: 18px !important;
    color: #000 !important;
    text-align: center;
}

.grayColor {
    color: #9D9D9F !important;
    font-weight: 400 !important;
}

.cancelIcon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 13px;
}

.orgDetails {
    display: flex;
    margin: 25px 0;
    cursor: pointer;
}

.dividerStyle {
    background-color: #eaeaea;
    height: 2;
}

.tablecontainer {
    max-height: 576px;
}

 .rewardtitle {
     font-size: 20px !important;
     color: #000;
     font-weight: 500;
     padding: 10px 0 20px;
 }

 .activityLogTitle {
    font-size: 20px !important;
    color: #000;
    font-weight: 500;
    padding: 10px 0 20px;
}

.topRewardSection {
    display: flex;
}

.imgContainer {
    height: 100px;
    padding: 10px 20px 0 0;
    width: 15%;
}

.imgContainer img {
    width: 100%;
    height: 100%;
}

.pointsContainer {
    padding: 10px;
    width: 20%;
}

.pointsEarned {
    font-size: 40px;
    font-weight: 500;
    color: #0243ca;
    text-align: center;
    padding-bottom: 23px;
}

.pointsText {
    color: #a0a0a0;
    text-align: center;
}

.points {
    font-size: 25px !important;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding: 22px;
}

.donateBtn {
    background: #009be9 !important;
    color: #fff !important;
    margin: 20px 0 !important;
    padding: 13px 23px !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    font-size: 14px !important;
}

.donateBtn:hover {
    background: #0af !important;
}

.btnImg {
    padding: 0 5px 0 10px;
    transform: rotateY(180deg);
    height: 13px;
}

.search {
    position: relative;
    background-color: #fff;
    height: 48px;
    margin-left: 0px !important;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-width: 1px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.8);
    width: 100%
}

.searchIconImg {
    height: 20px !important;
}

.searchIcon {
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    color: #3b95cc;
}

.inputInput {
    padding: 16px 16px 16px 0px !important;
    font-size: revert !important;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding-left: calc(1em + 40px) !important;
}

.disabledDonateBtn {
    background: #009be9 !important;
    color: #fff !important;
    border: 1px solid #ebebeb !important;
    margin: 50px auto !important;
    font-size: 15px !important;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 5px !important;
    padding: 10px 60px !important;
    opacity: 0.5;
}

.tableroot {
    width: 100% !important;
    overflow: auto !important;
    margin: 20px 0 30px !important;
    padding: 20px 30px !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
    border-radius: 15px !important;
}

.activityLogBtn {
   background-color: white;
   text-transform: capitalize;
   height: 70px;
   border: none !important;
}
.activityLogBtn span {
    text-transform: capitalize; 
}
.paperRoot {
    width: 100% !important;
    overflow: auto !important;
    margin: 20px 0 30px !important;
    padding: 0px !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
    border-radius: 15px !important;
}

.activeEventTabs {
    color: #009be2 !important;
    border: 1px solid #009be2 !important;
    background: #ffffff !important;
    box-shadow: 0px 0px 8px rgba(0, 61, 89, 0.2) !important;
}

.activityLogPageTable {
   margin: 40px 30px !important;
}

.r3Table {
   margin-left: 280px !important;
   width: unset !important;
}

@media only screen and (max-width: 600px) {

   .activityLogPageTable {
       margin: 30px 15px 70px !important;
    }

    .normalBtn,
    .activeBtn,
    .disabledBtn {
        font-size: 14px !important;
        margin-bottom: 15px !important;
    }

    .r1Wrapper {
        padding: 5px;
    }

    .tableroot {
        padding: 20px !important;
    }

    .paperRoot {
        padding: 0 !important;
    }

    .donateBtn {
        width: 100%;
    }

    .pointsContainer {
        width: 100%;
    }

    .imgContainer {
        width: 100%;
        padding: 10px;
    }

    .topRewardSection {
        flex-direction: column;
    }

    .rewardtitle, .activityLogTitle {
        font-size: 18px;
        text-align: center;
        padding: 10px;
    }

    .dialogWrap {
        width: 100%;
        padding: 20px;
    }

    .dialogWrap div {
        margin: 0;
        font-size: 14px !important;
    }

    .dialogWrap .orgDetails {
        margin: 15px 0;
    }

    .organisationLogo {
        height: 45px;
        width: 45px;
    }

    .donateOrg {
        width: 100%;
    }

    .orgImg {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 5px solid #fff;
    }

    .orgLogo {
        width: 40px;
        height: 40px;

    }

    .orgProfile {
        gap: 2px;
    }

    .orgProfileTetx {
        font-size: 20px;
    }

    .location {
        font-size: 16px;
        margin: 5px 0;
    }

    .aboutText {
        font-size: 18px;
    }

    .description {
        font-size: 14px;
        text-align: inherit;
    }

    .donateBtnContainer {
        width: 100%
    }

    .mainOrgWrapper {
        width: 100%;
    }

    .mainPrgSection {
        width: 100%;
    }

    .donatedPoints {
        font-size: 14px;
        margin: 20px 0 !important;
    }

    .goToProfile {
        font-size: 12px !important;
        width: 100%;
        padding: 10px !important;
    }

    .dialogWrapperInner {
        margin-bottom: 20px !important;
    }


    .pointsTable {
        padding: 10px 10px 20px;
    }

    .tableWrapper {
        margin-bottom: 45px !important;
    }

    .toolBar {
        margin: 60px 0 20px;
    }

    .backTitle {
        font-size: 16px !important;
    }
}

/* Customizable Area End */
