/* Customizable Area Start */
.forgotPwdMainContainer {
  background-color: #ffffff;
}

.forgotPwdOtpImgContainer {
  text-align: center;
}

.forgotPwdOtpImgLogo {
  width: 400px;
  height: 400px;
  margin: 150px 20px;
}

.forgotPwdOtpFormContainer {
  margin: 54px 100px;
  padding: 50px;
  background-color: #ffff;
}

.forgotPwdInputField {
  padding: 5px 50px;
}

.forgotPwdOtpTitle {
  color: #009be2;
  text-align: center;
  padding: 30px 0;
  line-height: 30px;
}

.forgotPwdBtn {
  margin: 188px 50px 50px;
}

.forgotPwdOtpField {
  color: #000000;
  line-height: 20px !important;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin: 20px 0 !important;
}

.forgotPwdOtpInput {
  height: 56px !important;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.forgotInnerInputText input {
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
}

.sendCodeBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
}

.sendCodeBtn .MuiButton-label {
  text-transform: initial;
}

.otpViewOvalImg {
  height: 150px;
  width: 150px;
  position: relative;
}

.otpImgContainer {
  text-align: center;
  margin-top: 40px;
}

.otpmsgImg {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 142px;
  right: 349px;
}

.errorMsg .errors {
  text-align: left;
  font-size: 14px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium";
  color: #fd1d1d;
}

.errorIconDiv img {
  height: 16px;
  width: 16px;
  margin-right: 7px;
  align-items: center;
}

/* Otp CSS */

.forgotPwdOtpMainContainer {
  background-color: #ffffff;
}

.forgotPwdImgContainer {
  text-align: center;
}

.forgotPwdImgLogo {
  width: 400px;
  height: 400px;
  margin: 150px 20px;
}

.forgotPwdFormContainer {
  background-color: #ffffff;
  margin: 54px 100px;
  box-shadow: 1px 6px 30px 1px #c5c5c4;
}

.forgotPwdOtpFormContainer {
  box-shadow: 1px 6px 30px 1px #c5c5c4;
}

.forgotPwdTitle {
  color: #009be2;
  text-align: left;
  padding: 80px 50px 10px 50px;
  line-height: 31px;
}

.forgotPwdField {
  color: #000000;
  line-height: 21px !important;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 15px !important;
  margin-top: 10px !important;
  /* font-size: 18px !important; */
}

.forgotPwdInput {
  height: 56px !important;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.119346);
  background: #ffffff;
  border-radius: 12px;
}
.innerOtpText input {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
}
.innerOtpErrorText input {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
  color: #ff0000 !important;
}
.verifyOtpBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 15px !important;
}

.verifyOtpBtn .MuiButton-label {
  text-transform: capitalize;
}

.otpImgContainer {
  background-image: linear-gradient(#00cbf3, #009be2);
  width: 168px;
  height: 167px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.otpImgContainer img {
  height: 82px;
  margin: auto;
  width: 82px;
}

.OtpInput input {
  width: 44px !important;
  margin: 2px;
  border: none;
  font: normal normal normal 26px var(--customFontFamily);
  padding: 6px 0 7px;
  min-width: 0;
  border-bottom: 1px solid #b1b1b1;
  background: inherit;
  border-radius: 0;
}

.otpInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0 !important;
}

.otpInput > div:first-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.OtpInput input:focus {
  border-color: #000;
}

.resendCodeOtpContainer {
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 20px !important;
}

.resendCodeOtpLink {
  color: #009be1 !important;
}

.forgotPwdOtpMsg {
  text-align: center !important;
  padding: 5px 30px;
}

.otpContainer {
  padding: 0 30px;
}

@media only screen and (max-width: 1365px) {
  .forgotPwdOtpFormContainer {
    margin: 20px 100px;
    background-color: #ffffff;
  }

  .forgotPwdFormContainer {
    margin: 54px 100px;
  }
}

@media only screen and (max-width: 1279px) {
  .forgotPwdOtpImgLogo {
    width: 350px;
    height: 350px;
  }

  .forgotPwdOtpFormContainer {
    margin: 20px;
  }

  .forgotPwdImgLogo {
    width: 350px;
    height: 350px;
  }

  .forgotPwdFormContainer {
    margin: 60px;
  }
}

@media only screen and (max-width: 959px) {
  .forgotPwdOtpField {
    font-size: 14px !important;
  }
  .resendCodeOtpContainer {
    font-size: 14px !important;
  }
  .forgotPwdOtpMainContainer {
    display: inline !important;
  }

  .forgotPwdOtpImgLogo {
    width: 200px;
    height: 200px;
    margin: 0 20px 0 20px;
  }

  .forgotPwdOtpFormContainer {
    padding: 0px;
    margin: 20px;
    background-color: inherit !important;
    box-shadow: none;
  }

  .forgotPwdOtpTitle {
    margin-top: 0 !important;
    padding: 0;
    text-align: center !important;
  }

  .signInInputField {
    padding: 5px;
  }

  .forgotPwdMainContainer {
    display: inline !important;
  }

  .forgotPwdImgLogo {
    width: 200px;
    height: 200px;
    margin: 0 20px 0 20px;
  }

  .forgotPwdFormContainer {
    padding: 0;
    margin: 20px;
    box-shadow: none !important;
  }

  .forgotPwdTitle {
    margin-top: 0 !important;
    padding: 0;
    text-align: center !important;
    font-size: 26px !important;
  }

  .forgotPwdOtpImgContainer {
    display: none;
  }

  .otpImgContainer {
    margin-bottom: 30px;
    margin-top: 25px;
  }

  .forgotPwdBtn {
    margin: 0;
  }

  .forgotPwdInputField {
    padding: 5px 0;
  }
  .forgotPwdField {
    font-size: 14px !important;
  }
  .otpBtnContainer {
    /* margin: 70px 20px 20px !important; */
    margin: 20px 0 0 !important;
  }
  .forgotPwdFormContent {
    min-height: calc(100vh - 415px);
    width: 100%;
  }
  .forgotPwdFooterContent {
    display: block;
  }
  .sendCodeBtn {
    margin-bottom: 0 !important;
  }
  .verifyOtpBtn {
    margin-bottom: 0 !important;
  }
  .otpInput  {
    margin: 95px 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .OtpInput input {
    border: none;
    border-bottom: 1px solid #b1b1b1;
    background: inherit;
    border-radius: 0;
  }
  .forgotPwdOtpMsg {
    text-align: left !important;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 539px) {
  .otpInput {
    max-width: 340px;
  }
}

@media only screen and (max-width: 379px) {
  .forgotPwdOtpImgLogo {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 359px) {
  .forgotPwdOtpImgLogo {
    width: 250px;
    height: 250px;
    margin: 50px 0 0 0;
  }

  .forgotPwdImgLogo {
    width: 250px;
    height: 250px;
    margin: 50px 0 0 0;
  }
}
/* Customizable Area End */
