.label {
  display: inline-block;
  /* font-weight: 400px !important; */
  font-size: 18px !important;
  margin-bottom: 5px !important;
  padding-left: 5px;
}
*:focus {
  outline: none;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
.labelHeading {
  display: inline-block;
  font-size: 18px !important;
  margin-bottom: 5px !important;
  /* padding-left: 10px; */
}
.labelWhoareWe {
  display: inline-block;
  font-size: 18px !important;
  font-weight:600 !important;
  padding-left: 10px !important;
  margin-left: 17px !important;
  margin-top: 10px !important
}
.cardUploadImage {
  padding: 5px 12px 0 12px !important;
}
.heading {
  display: inline-block;
  font-size: 22px !important;
  margin-left: 10px;
}

.mainWrapper {
  padding: 15;
  /* width: 90%; */
  border-radius: 12 !important;
  margin: auto;
  margin-top: 30px;
}
.textInput {
  width: 50%;
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  background: #ffffff;
  border: 1px solid #d9d4d4;
  font-size: 16px;
}

.selectInput {
  margin-bottom: 10px;
  border-radius: 12;
  width: 100%;
}
.root {
  border-radius: 12px !important;
}
.root :focus {
  border: none;
}
.focused {
  border-color: red;
}
.notchedOutline {
  border-color: red;
}
.notchedOutline :hover {
  border-color: red;
}

.multiLineInput {
  /* height: 150px; */
  width: 50%;
  border: 1px solid #d9d4d4;
  border-radius: 12px;
  padding: 13px !important;
}
/* .multiLineInput :placeholder-shown {
  background-color: red;
  margin-top: -20px;
} */
.divWrapper {
  margin-bottom: 20px;
  padding-left: 10px;
  margin-left: 10px;
  margin-top: 20px;
}
.activeEventTabs {
  color: #009be2 !important;
  border: none !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 8px rgba(0, 61, 89, 0.5) !important;
  border-right: 2px solid #009be2 !important;
  /* color: #009be2 !important;
  border: none !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 8px 0px rgb(0 155 226 / 21%) !important;
  border-right: 2px solid #009be2 !important; */
}

.sdContainer {
  position: relative;
  float: left;
}

.sd {
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.openButton {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.openButton button {
  border: none;
  background: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  /* background-color: red; */
  color: red;
  /* -webkit-appearance: none; */
}

.locationField {
  border-radius: 12px !important;
}

.newEventTitle {
  display: flex;
  justify-content: space-between;
}
.userModal {
  border-radius: 12px;
}
.modalDiv {
  padding: 10;
  border-bottom: 2px solid #eaeaea;
  display: flex;
}

.modalDivEnd {
  padding: 10;
  display: flex;
}

.modalTitle {
  padding: 10;
  border-bottom: 2px solid #eaeaea;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userName {
  font-size: 16px !important;
}

.userEmail {
  font-size: 12px !important;
}

.modalHeader {
  font-size: 14px !important;
}
.modalText {
  font-size: 14px !important;
}
.calenderIconBox {
  height: 25;
  width: 25;
  position: "absolute";
  top: 13;
  right: 10;
}
.clockIconContainer {
  height: 18px;
  width: 17px;
  position: absolute;
  top: 50px;
  right: 51%;
  background-color: #fff;
  pointer-events: none;
}
.modalBox {
  height: auto;
  background-color: white;
  position: relative;
  top: 22%;
  text-align: center;
  left: 45%;
  width: 30%;
  border-radius: 15px;
}
.warningIcon {
  margin: 40px 0 20px 0;
}
.optionsLabel {
  margin-top: 55px !important;
}
.checked {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  position: relative;
}

.blue {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 4px;
  right: 4px;
  background-color: #009be2;
  border-radius: 2px;
}

.unchecked {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  position: relative;
}

