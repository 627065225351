/* Customizable Area Start */
.resetPwdMainContainer {
  background-color: #ffffff;
}

.imgContainer {
  text-align: center;
}

.resetImgLogo {
  width: 400px;
  height: 400px;
  margin: 150px 20px;
}

.resetPwdFormContainer {
  margin: 54px 120px;
  background-color: #ffffff;
  box-shadow: 1px 6px 30px 1px #c5c5c4;
}

.resetPwdTitle {
  text-align: left;
  line-height: 30px;
  font-size: 36px !important;
}

.resetPwdField {
  color: #000000;
  line-height: 16px !important;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}

.resetPwdInput {
  height: 56px !important;
  padding: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.119346);
  border-radius: 12px;
}

.resetInnerInputText input {
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
}

.paperStyle {
  padding: 20;
  margin: 20vh;
  border-radius: 10px;
}

.resetPwdBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-top: 40px !important;
  margin-bottom: 10px !important;
}

.resetPwdBtn .MuiButton-label {
  text-transform: capitalize;
}

.signUpLink {
  color: #009be1 !important;
}

.resetPwdConditionMsg {
  text-align: right;
  color: #b1b1b1;
  font-size: 16px !important;
}

.resetPwdInputField {
  padding: 5px 50px;
}

.resetPwdBtnContainer {
  margin: 50px 50px 40px 50px;
}

.errorMsg .errors {
  text-align: left;
  font-size: 14px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium";
  color: #fd1d1d;
}

.passwordIconBox {
  position: absolute;
  top: 12px;
  right: 14px;
}

.pwdHideShowIcon img {
  height: 24px;
  width: 24px;
}

@media only screen and (max-width: 1365px) {
  .resetPwdFormContainer {
    margin: 50px 100px;
  }
}

@media only screen and (max-width: 1279px) {
  .resetImgLogo {
    width: 350px;
    height: 350px;
  }

  .resetPwdFormContainer {
    margin: 50px;
  }
}

@media only screen and (max-width: 959px) {
  .resetPwdMainContainer {
    display: inline !important;
  }

  .resetImgLogo {
    width: 200px;
    height: 200px;
    margin: 0 20px 0 20px;
  }

  .resetPwdFormContainer {
    margin: 20px;
    padding: 0;
    box-shadow: none !important;
    box-shadow: none;
  }

  .resetPwdTitle {
    margin-top: 0 !important;
    padding: 0;
    text-align: center !important;
    font-size: 26px !important;
  }

  .resetPwdInputField {
    padding: 5px 0;
  }

  .resetPwdBtnContainer {
    margin: 80px 0 0 0;
  }

  .resetPwdBtn {
    margin-top: 10px !important;
  }

  .resetPwdField {
    font-size: 14px !important;
  }

  .resetPwdConditionMsg {
    font-size: 12px !important;
  }

  .resetPwdFormContent {
    min-height: calc(100vh - 500px);
    width: 100%;
  }

  .resetPwdFooterContent button {
    display: block;
  }
}

@media only screen and (max-width: 379px) {
  .resetPwdFormContainer {
    margin: 20px;
  }
}

/* Customizable Area End */
