.mainContainer {
  justify-content: center;
  align-items: center;
  display: grid !important;
}
.landingPageContainer {
  background-color: white;
  border-radius: 15px;
  width: 30rem;
  align-items: center;

}
.appLogoContainer {
  justify-content: center;
  align-items: center;
  margin: 6rem;
}
.appLogo {
  height: 12rem;
  width: 12rem;
}
.footerContainer {
  margin: 2rem 0;
}
.signInAsBtn {
  width: 100% !important;
  height: 3.5rem;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 1rem !important;
  font-size: 18px !important;
  font-family: "Rene_Bieder_Milliard_Medium" !important;
  font-weight: 500 !important;
}
.registerAsBtn {
  width: 100% !important;
  height: 3.5rem;
  background-color: #fff !important;
  color: 009be2 !important;
  border-radius: 10px !important;
  margin-bottom: 1rem !important;
  font-size: 18px !important;
  font-family: "Rene_Bieder_Milliard_Medium" !important;
  font-weight: 500 !important;
  border: 2px solid lightgray !important;
}
