/* Modal CSS  */
.modalWrapper {
	background-color: #8d8d8db0;
}

.modalBox {
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background: #fff;
	max-width: 495px;
	min-width: 384px;
	min-height: 200px;
	padding: 15px;
	border-radius: 12px;
	text-align: center;
	flex-direction: column;
}

.modalBox .modalBtn {
	color: #fff !important;
	background-color: #009be2 !important;
	margin: 25px auto;
	width: 50% !important;
	height: 45px;
	font-size: 18px;
	border-radius: 12px;
}

.modalBox .checkSubmit {
    color: #009be2;
    border: 2px solid #009be2;
    border-radius: 50%;
    padding: 10px;
    height: 55px;
    width: 55px;
    font-size: 25px;
    margin-bottom: 20px;
	margin-top: 30px;
} 