.searchreletiveenv {
  position: relative;
}

.searchfilterEnv {
  position: absolute;
  width: -webkit-fill-available;
  max-width: 82%;
  margin-top: 56%;
  right: 121px;
  border-radius: 15px !important;
  border: "1px solid lightgray" !important;
}

@media only screen and (max-width: 600px) {
  .tabsAlign {
    margin-left: 0rem;
  }

  .dashboardnavicons {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }
}

.changeLoc {
  margin-right: 40px;
}

@media only screen and (max-width: 600px) {
  .changeLoc {
    margin-right: 0rem;
  }
}

.leftmenuDetails {
  /* background-color: white; */
  height: 100%;
}

/* .userprofileMain{
    margin-left:35px;
    } */
.backIconWeb {
  display: flex;
  align-items: center;
}

.settingContent {
  margin-left: 50px;
}

.tabsRoot>div>div {
  margin-bottom: "50px";
  background-color: white;

  justify-content: space-between;
  padding: 0 3rem;
}

.filterPositions {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate3d(575px, 119px, 0px);
  will-change: transform;
}

@media only screen and (max-width: 600px) {
  .rightmenuDetails {
    display: none;
  }

  .hidebackbutton {
    display: none;
  }

  .mobilemenuposion {
    margin-left: -40px;
  }

  .tabsRoot>div>div {
    margin-bottom: "50px";
    background-color: white;

    justify-content: none;
    padding: 0rem;
  }

  .tabContainer {
    padding: 0px !important;
  }
}

.pastImg {
  margin-top: -36px;
  border-radius: 15px;
}

/* Dashboard CSS Start */

.headerContainer {
  margin: 20px 0;
}

.headerText {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.subHeaderText {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-top: 5px !important;
}

.daysCount {
  color: #0096FF;
  font-weight: 500;
}

.subContent {
  padding: 1rem;
  border-radius: 10px !important;
}

.titleContainer {
  font-size: 13px;
}

.digitContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.numberText {
  color: #0096FF;
  font-weight: 600 !important;
  font-size: 25px !important;
}

.percentageText {
  color: #55D7AE;
  font-weight: 600 !important;
  margin-top: 15px !important;
}

.percentageWarnText {
  color: #C94849;
  font-weight: 600 !important;
  margin-top: 15px !important;
}

.arroWImgSpan {
  cursor: pointer;
  margin: auto 8px;
}

.viewDownImg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.viewUpImg {
  height: 10px;
  width: 20px;
  cursor: pointer;
}

.errorMsg .errors {
  text-align: left;
  font-size: 14px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  color: #fd1d1d;
}

/* Dashboard CSS End */
.trialTextGreen {
  color: #009065;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 20 !important;
  font-size: 16 !important;
}
.trialTextRed {
  color: #fd1d1d;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 20 !important;
  font-size: 16 !important;
}
.subBtn {
  background: #fefefe !important;
  border: 1px solid #009be2 !important;
  border-radius: 30px !important;
  height: 30 !important;
  margin-left: auto !important;
  margin-right: 20px !important;
  color: #009be2 !important;
  text-transform: none !important;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
}

.trialText {
  color: #009065;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 20 !important;
  font-size: 16 !important;
}
.trialDiv {
  background-color: #ffffff;
  border: 1px solid #009be2;
  padding: 10;
  display: flex;
  position: relative;
  left: -24;
  width: 104.5%;
}
.subcribeBtn {
  background: #fefefe !important;
  border: 1px solid #009be2 !important;
  border-radius: 30px !important;
  height: 30 !important;
  margin-left: auto !important;
  margin-right: 36px !important;
  color: #009be2 !important;
  text-transform: none !important;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
}
.dashboardText {
  font-size: 26 !important;
  font-weight: bold !important;
}
.dividerLine {
  position: relative !important;
  left:-25px !important;
  margin-top: 35px !important;
  height: 2 !important;
  background: white !important;
  width: 110% !important;
}

.multiLineInput {
  width: 42%;
  border: 1px solid #d9d4d4;
  border-radius: 12px;
  padding: 13px !important;
  background: white;
  text-align: justify;
}
.divWrapper {
  margin-bottom: 20px;
  margin-top: 20px;
}
.brodcastBtn{
  position: relative;
  left: 33%;
  width: 9%;
  top:-35px;
  height: 5% !important;
  color: white;
  background-color:#009be2 !important;
  text-transform: none !important;
  border-radius: 8px !important;
}
.disableBroadcastbtn {
  background-color: rgba(0, 155, 226, 0.2) !important;
  position: relative;
  left: 33%;
  width: 9%;
  top:-35px;
  height: 5% !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 8px !important;
}
.broadcastList{
  background-color: white  !important;
  border-radius: 15px !important;
  margin-top: 10px;
  margin-bottom: 40px !important;
}
div[class*='MuiAccordion-rounded']:last-child {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}
.broadcastText{
  padding: 15px;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: black !important;
}
.listBroadcasts{
  display: flex;
  justify-content: space-between;
  margin-top: 5px !important;
}
.counter {
  position: relative;
  top: -45px;
  left: 38%;
  color: '#A2A2A2' !important;
}
.largeCounter  {
  position: relative;
  top: -45px;
  left: 37%;
  color: '#A2A2A2' !important;
}
.listDesc {
    margin: 0 80px !important;
    width: 650px !important;
    overflow-wrap: break-word !important;
    text-align: justify !important;
}
.listWrap {
    margin: 0 80px !important;
    width: 650px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
.listTime {
  margin-left: 10px !important;
}
.broadcastModelBtn {
  margin: 10px !important;
  padding: 5px 15px !important;
  color: white;
  background-color:#009be2 !important;
  text-transform: none !important;
  border-radius: 8px !important;
}

div[class*='MuiAccordion-root'] {
  position:  unset !important;
}

/* Dashboard CSS End */
.banner{
  cursor: pointer;
}
