/* Customizable Area Start */

.titleFont {
    font-size: 20px !important;
    font-weight: bold !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.backIconWeb {
    display: flex;
    align-items: center;
    margin: 50px 0 20px 45px;
}

.openEvent {
    font-weight: bold !important;
    font-size: 18;
    color: #0e0d0e;
    margin-right: 20px;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.activityDetails {
    font-size: 20px !important;
    color: #000;
    margin-left: 10px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
    font-weight: bold !important;
}

.projectFundingTitle {
    font-size: 20px !important;
    color: #000;
    margin-left: 10px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
    font-weight: bold !important;
}
.leftCard {
    text-align: center;
    position: relative;
    margin: 40px 80px 30px;
}

.leftCardR3 {
    text-align: center;
    position: relative;
    margin: 25px 55px 10px;
}

.imageLoading {
    display: flex;
    justify-content: center;
    border-radius: 16px;
    width: 100%;
    align-items: center;
    height: 400px;
    border: 1px solid black;
}

.hideImage {
    display: 'none';
}

.cardImage {
    width: 100%;
    height: 400px;
    border-radius: 16px;
    object-fit: cover;
}

.FavoriteIcon {
    position: absolute;
    background-color: #fff;
    padding: 11px;
    width: 45px !important;
    height: 45px !important;
    right: 15px;
    top: 15px;
    border-radius: 50%;
    color: red !important;
    cursor: pointer;
}

.messageText {
    color: #009be2;
    font-size: 16px !important;
    margin: 14px 0 9px !important;
}

.messageWrap {
    cursor: pointer;
}

.addToCal {
    font-weight: 400;
    font-size: 16;
    color: #009be2;
    cursor: pointer;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.openEventText {
    font-weight: 600;
    font-size: 16 !important;
    color: #0e0d0e !important;
    margin-right: 20px !important;
}

.availablePlace {
    font-size: 16 !important;
    color: #009be2;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.avatars {
    margin: 10px 0;
}

.joinchatText {
    color: #009be2;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
    margin-left: 15px;
    cursor: pointer;
}

.FavoriteBorderIcon {
    position: absolute;
    background-color: #fff;
    padding: 11px;
    width: 45px !important;
    height: 45px !important;
    right: 15px;
    top: 15px;
    border-radius: 50%;
    cursor: pointer;
    color: #292d32;
}

.eventDetails {
    padding: 00px 80px 30px 95px;
    border-bottom: 1px solid #e9e9e9;
}



.avatarImgcontainer {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.avatarImg {
    width: 23;
    height: 23;
    border-radius: 50%;
    z-index: 1;
}

.eventdate_loc {
    font-weight: 400;
    font-size: 16;
    color: #1e1e1e;
}

.about {
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0;
}


.description {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
}

.detailDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
    margin-bottom: 15px;
    word-wrap: break-word ;
}

.fund {
    padding: 7px 15px;
    overflow: auto;
}

.location {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: bold;
}

.locationWrapper {
    cursor: pointer;
}

.getDirection {
    color: #009be2;
    font-size: 22px;
    font-weight: 400;
    display: inline;
    margin-bottom: 20px;
}

.directionImg {
    display: inline;
    height: 25px;
    width: 25px;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 10px;
}


.whiteBtn {
    margin: 40px 0 !important;
    color: black !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 12px !important;
    border: 1px solid #979797 !important;
    text-transform: capitalize !important;
    width: 100%;
    padding: 10px !important;
    margin-right: 20px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.BlueBtn {
    margin: 40px 0 !important;
    color: #fff !important;
    background: #009be2 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 12px !important;
    border: 1px solid #009be2 !important;
    text-transform: capitalize !important;
    width: 100%;
    padding: 10px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.donateButton {
  margin-bottom: 60px !important;
}

.grayBtn {
    margin: 40px 0 !important;
    color: #fff !important;
    background: gray !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 12px !important;
    border: 1px solid gray !important;
    text-transform: capitalize !important;
    width: 100%;
    padding: 10px !important;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.spaceLeft {
    margin-left: 20px !important;
}

.imageTextWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 7px 0;
}

.userInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 14px;
}

.spaceBetweenDivs .userInfo {
    cursor: pointer;
}

.spaceBetweenDivs .defaultCursor {
    cursor: default;
}

.fundCampaign .userInfo {
    margin: 10px 0 0;
}


.userName {
    font-size: 18px !important;
    color: #1e1e1e;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.joinChat {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #009be2;
    font-size: 18px;
    font-weight: 400;
}

.joinChatTextOpen {
    margin-left: 15px;
    cursor: pointer;
    color: #009be2;
    font-weight: 400;
    text-decoration: underline;
}

.joinChatTextClose {
    margin-left: 15px;
    color: #C8C8C8;
    font-weight: 400;
    text-decoration: underline;
}

.boldButton {
    font-weight: bold !important;
}

.toolBar {
    border-bottom: 1px solid #e9e9e9;
    min-height: 0px !important;
}

.toolBarR3 {
    border-bottom: none;
    padding: 5px !important;
}

.divider {
    display: block;
    height: 1px;
    opacity: .14127605;
    border: 1px solid #979797;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}

.eventContainer {
    margin-left: 50px;
    background-color: white;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.eventContainer .MuiTypography-root {
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.chatIcon {
    height: 1em;
    width: 1em;
    font-size: 2rem;
    margin-left: 20;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}


.spaceBetweenDivs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialogStyle {
    padding: 40px 40px 20px;
    border-radius: 16;
    width: 500px;
    box-shadow: 0px 0px 8px rgba(147, 145, 145, 0.5);
}

.dialogStyle div {
    text-align: center;
    justify-content: center;
}

.dialogStyle .userInfo {
    margin: 10px 0 20px;
}

.dialogStyle .userName {
    font-size: 18px !important;
}

.dialogStyle .eventdate_loc {
    font-size: 16px !important;
    color: #1e1e1e;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.dialogStyle .dialogBoxText {
    font-weight: bold;
    font-size: 16px !important;
    line-height: 27px;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
}

.dialogStyle .BlueBtn {
    margin: 15px 0 !important;
}

.dialogStyle .whiteBtn {
    margin: 0 !important;
}

.dialogStyle .multipleAvatarStyle,
.dialogStyle .multipleAvatarStyle img {
    height: 32px;
    width: 32px;
}

.avatars :last-child {
    height: 24px;
    width: 24px;
    background: #009be2;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}

.avatars div {
    height: 24px;
    width: 24px;
    background: #009be2;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}

.dialogStyle .avatars :last-child {
    height: 32px;
    width: 32px;
}



.fundUpdates {
    border-radius: 12px;
    border: 2px solid #009be2;
    background: #ffffff;
    height: 100%;
    position: relative;
}

.fundUpdates svg {
    color: #009be2;
}

.fundUpdates .cameraIcon svg {
    fill: #009be2;
    height: 80px;
    width: 80px;
}

.commentIcon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer;
    background: #fff;
    padding: 5px;
    height: 40px !important;
    width: 40px !important;
    border-radius: 10px;
    z-index: 2;
}

.fundUpdateNotes { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    align-items: center;
}
.Notesfund {
    padding-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.Notesfund .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cameraIcon {
    height: 100%;
    width: 100%;
}

.cameraIcon img, .cameraIcon video, .cameraIcon div {
    height: 465px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.tabBtn {
    display: flex;
    align-items: center;
    background-color: #009be2;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.tabBtn:last-child {
    margin-bottom: 0px;
}

.tabBtn.tabBtnActive {
    background-color: #fff;
    border: 2px solid #009be2;
}

.tabBtn.tabBtnActive .btnIcon {
    background: #009be2;
}


.tabBtn .btnIcon {
    border-radius: 10px;
    border: 2px solid #009be2;
    background: #ffffff;
    width: 80%;
}

.tabBtn.tabBtnActive svg {
    color: #fff;
    fill: #fff;
}

.btnIcon svg {
    color: #009be2;
    fill: #009be2;
    height: 40px;
    margin: 17px auto;
    width: 100%;
}

.btnText {
    background-color: #009be2;
    color: #fff;
    font-size: 16px;
    border-radius: 12px;
    padding: 14px 5px;
}

.tabBtn.tabBtnActive .btnText {
    background-color: #fff;
    color: #009be2;

}

.updatesSection {
    color: #009be2;
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
}

.commentInput {
    resize: none;
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 400;
    padding: 15px;
    margin-top: 9px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 12px;
}



.commentTextLength {
    position: absolute;
    right: 5px;
    bottom: 2px;
    font-size: 10px;
}

/* Fund campaign page styles */

.fundCampaign {
    padding: 0px 80px 40px 80px;
    border-bottom: 1px solid #e9e9e9;
}

.fundCampaignR3 {
    padding: 0px 55px 40px;
}

.ml0 {
    margin-left: 0px;
}

.fundCampaign .about {
    margin: 30px 0 15px;
    font-size: 22px;
}

.fundCampaign .description {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 28px;
}
.journeybutton {
    color: #009be2 !important;
    font-size: 18px !important;
    border: 1px solid #009be2 !important;
    text-transform: initial !important;
    padding: 10px 15px !important;
    cursor: pointer;
}
.amountPickerWrapper {
    background: #fff;
    border: 3px solid #ebebeb;
    border-radius: 12px !important;
    padding-left: 25 !important;
    padding-right: 25 !important;
    box-sizing: border-box;
}
.amountPickerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 5px; 
}
.amountPickerText {
    font-size: 20px !important;
    color: #000000;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
}
.amountrangeSliderColor {
    height: 10px !important;
    background: transparent;
    border-radius: 9px;
    margin-left: 8px;
}
.raisedText {
    font-size: 18px !important;
    margin: 25px 0 10px !important;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
}

.dialogWrapper {
    padding: 20px 30px;
    border-radius: 16px !important;
}

.fundFeedback {
    width: 420px;
}

.dilogContainerWidth {
    position: relative;
}

.commentTextCount {
    position: absolute;
    font-size: 12px !important;
    bottom: 10;
    right: 10;
    color: #a4a3a4;
}
.dialogWrapper .BlueBtn {
    margin: 20px 0 !important;
}

.updateresponsive {
   height: 465px;
   overflow-y: scroll;
}

::placeholder {
    color: #9a9a9a;
    font-weight: 400;
    font-size: 14px;
    font-family: Rene_Bieder_Milliard_Book, Serif, Sans-serif !important;
    opacity: 1; /* Firefox */
}
  

@media only screen and (max-width: 600px) {
    .backIconWeb {
        margin: 0px;
    }
    
    .titleFont {
        font-size: 16px !important;
    }

    .leftCard {
        margin: 20px;
    }

    .projectJourney {
        margin-top: 45px;
    }

    .eventDetails {
        padding: 00px 20px 30px 25px;
    }

    .cardImage {
        height: 248px;
    }

    .eventContainer {
        margin-left: 0px;
    }

    .userName,
    .joinchatText {
        font-size: 14px !important;
    }

    .messageText {
        display: none;
    }

    .eventdate_loc,
    .addToCal,
    .availablePlace {
        font-size: 12px !important;
    }

    .about {
        font-size: 18px !important;
        margin: 20px 0;
    }

    .cameraIcon img, .cameraIcon video, .cameraIcon div {
        height: 295px;
    }

    .description,
    .location,
    .getDirection {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .detailDescription {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    .dialogStyle {
        padding: 25px 20px;
        border-radius: 16;
        width: 100%;
        box-shadow: 0px 0px 8px rgba(147, 145, 145, 0.5);
    }

    .dialogStyle .userName {
        font-size: 14px !important;
    }

    .dialogStyle .eventdate_loc {
        font-size: 12px !important;
    }

    .dialogStyle .multipleAvatarStyle,
    .dialogStyle .multipleAvatarStyle img {
        height: 24px;
        width: 24px;
    }

    .projectFundingTitle {
        font-size: 16px !important;
    }

    .dialogStyle .avatars :last-child {
        height: 24px;
        width: 24px;
    }

    .directionImg {
        margin-bottom: 5px;
    }

    .fundCampaign {
        padding: 20px 25px;
    }

    .fundUpdates {
        height: 300px;
        margin-bottom: 40px;
    }

    .tabBtn svg {
        height: 58%;
        padding: 0px;
        width: 100%;
    }

    .updateresponsive {
        display: flex;
        height: auto; 
        overflow: auto; 
    }

    .tabBtn {
        margin-right: 12px;
        flex-wrap: wrap;
        min-width: 72px;
    }

    .tabBtn:last-child {
        margin-bottom: auto;
        margin-right: 0px;
    }

    .dialogWrapper {
        width: 100%;
        padding: 20px;
    }

    .tabBtn .btnIcon {
        width: 100%;
        height: 80px;
    }

    .btnText {
        text-align:center;
        font-size: 10px;
        margin: 0 auto;
        padding: 3px;
    }
}

.joinChatDisable{
    color: rgb(0, 150, 255);
    margin-left: 15px; 
    cursor: none;
    opacity: 1.5;
}

/* Customizable Area End */