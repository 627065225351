/* Customizable Area Start */
.AppLogoMainDiv{
    margin:  15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* width: 100%; */
    
}

.ApLogoMainGrid{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}


.AppLogoImg{
    width: 302px;
    height: 298px;
}

@media only screen and (max-width:600px){
	.AppLogoImg{
        width: 170px;
        height: 160px;
    }
    .ApLogoMainGrid{
        background-color: white;
    }

    .AppLogoMainDiv{
     display: none;
        
    }
}

/* admin accountcreation flow css  */
.innerdivadjust{
    padding:30px;
    border-radius: 16px;
}

@media only screen and (max-width:600px){
    .innerdivadjust{
        padding:0px;
    }
}


 /* Customizable Area End */