/* My pledge  Start*/
.myPledge {
  margin-top: 20px;
  margin-bottom: 20px
}

.publishNeedButtons {
  background: #FFFFFF;
  border: 1px solid #D5D5D5 !important;
  border-bottom-left-radius: 12px !important;
  border-top-left-radius: 12px !important;
  color: #009be2 !important;
}

.myNeedsTabs {
  box-shadow: 0px 0px 8px rgba(212,212,212,0.5);
  border: 1px solid #e8e8e8 !important;
}

.needTabs {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
}

.myPledgeTab {
  /* min-width: 50% !important; */
  text-transform: none !important;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);

}

.myPledgeTabActive {
  box-shadow: 0 0 8px rgb(0 0 0 / 12%);
  color: #009be2;
}

/* Model UI */

.modalBox {
  height: auto;
  background-color: white;
  position: relative;
  top: 28%;
  text-align: center;
  left: 45%;
  width: 20%;
  border-radius: 15px;
}

.myNeedmodalBox {
  height: auto;
  background-color: white;
  position: relative;
  top: 50%;
  text-align: center;
  left: 50%;
  width: 350px;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}

.warningIcon {
  margin: 40px 0 20px 0;
}




/* My pledge  End*/
.userprofileMain {
  margin-left: 35px;
}

.backIconWeb {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .userprofileMain {
    margin-left: 0px;
  }

  /* .backIconWeb{
            display: none;
          } */
}

/* User Edit Profile   nandini*/
.profileIconBox {
  display: flex;
  justify-content: center;
}

.profileIcon {
  height: 80px !important;
  width: 80px !important;
  margin-top: 60px;
  margin-bottom: 25px;
}

.label {
  color: #359ce2
}

.editInputField {
  border: 1px solid #c2c2c2;
  margin-top: 7px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  box-shadow: 0 0 8px rgb(0 0 0 / 12%);
}

.bioInput {
  border: 1px solid #c2c2c2;
  margin-top: 7px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 90px;
  padding: 0 15px;
  box-shadow: 0 0 8px rgb(0 0 0 / 12%);
}

.edit {
  position: absolute;
  right: 30px;
  top: 98%;
}


/* userprofile main shivani  */

.profileContainer {
  flex: 1;
  margin-top: 30px;
}

.userDetail {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.userCompanyLogo {
  /* height: 50px; */
  width: 100px;
}

.userProfileContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;

}

.profilePicContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.personalInfo {
  margin-left: 20px;
}

.profilePicimg {
  width: 50%;
  height: 50%;
}

.profilePicimg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #009be2;
  padding: 7px;

}

.profilebio {
  margin-top: 50px;
}

@media only screen and (max-width: 600px) {

  .profilePicimg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #009be2;
    padding: 7px;

  }
  .warningIcon {
    display: none;
  }
  .alertText {
    padding-top: 30px;
  }
}

.connectionsDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.innerconnection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




.bottomTabs {
  display: flex;
  flex-direction: "row";
  /* justify-content: center;
      align-items: center; */
}

.tabItems {
  display: flex;
  flex-direction: "row";
  justify-content: center;
  align-items: center;
  text-align: center;
}

.plusIconBox {
  height: 60px;
  width: 60px;
  z-index: 3;
  position: fixed;
  top: 80%;
  right: 30px;
  background-color: #009be2;
  border-radius: 85px;
}

.plusIconBox img {
  height: 100%;
  width: 100%;
}

.publishNeedResponsiveIcons {
  z-index: 3;
}


.publishNeedResponsivePlusIcon {
  height: 60px;
  width: 60px;
  margin-left: -20px;
  border-radius: 100%;
  background-color: #009be2;
}

.modalBoxPublish {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: #fff;
  max-width: 550px;
  min-width: 425px;
  min-height: 250px;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .modalBoxPublish {
    min-width: 348px;
  }
}

.modalWrapper {
  background-color: #8d8d8db0;
}

.AvatarGroup {
  position: absolute;
  bottom: 15px;
  left: 350px;
}