.listtitle>span {
	overflow: hidden;
	text-overflow: ellipsis;
}

.itemList {
	position: relative;
}

.itemList::after {
	content: "";
	position: absolute;
	opacity: 0.1;
	width: calc(100% - 32px);
	height: 1px;
	background: #979797;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.notifiDate {
	float: right;
	width: 65px;
	line-height: 12px;
	text-align: center;
}

.notifiTitle {
	padding: 8px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.notifiTitle h2 {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: -0.4px;
	color: #000000;
}

.overflowPara {
	font-size: 12px;
	color: rgb(0, 0, 0);
	line-height: 17px;
}

.lessDetail,
.moreDetail {
	height: 35px;
	overflow: hidden;
	display: inline-block;
	/* position: relative; */
}

.moreDetail {
	height: auto;
}

.clickmoreBtn {
	position: absolute !important;
	bottom: -4px;
	background-color: #fff !important;
	z-index: 1;
	padding: 0 2px !important;
	width: 150px;
}

.moreDetail .clickmoreBtn {
	bottom: -4px;
}

.leftIconImg {
	height: 20px;
	width: 20px;
}

.connectionBtn {
	margin-top: 10px;
}

.acceptBtn {
	color:#fff !important; 
	background-color: #009be2 !important;
	border: none !important;
	height: 30px;
	border-radius: 8px !important;
	margin-right: 10px !important;
	width: auto;
}

.cancelBtn {
	color: #000000 !important;
	border: 2px solid lightgray !important;
	border-radius: 8px !important;
	height: 30px;
}

.back {
	display:none;
   }

.noti{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between
}

.divide{
	display: none;
}

@media only screen and (max-width: 600px) {
	.divide{
	display: block;
	margin-top:50px !important
	}
	
	.noti {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background-color: white;
		z-index: 1000; /* Set a higher z-index to ensure it's above other elements */
		margin-top: -1px !important;
		padding-top: 3%;
		padding-bottom: 3%;
	  flex-direction: row;
	  align-items: flex-start;
	  justify-content: start;
	}
  
	.noti > div:last-child {
	 display:none;
	}

	.back {
		display:block;
	   }
  } 

