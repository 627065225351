.label {
  font-family: "Rene_Bieder_Milliard_Book" !important;
  font-size: 16px;
  font-weight: 400px;
  margin-bottom: 10px !important;
} 
.btn {
  text-transform: capitalize !important;
  float: right !important;
  background-color: #009be2 !important;
  border-radius: 12px !important;
  margin: 110px 15px 10px 15px !important;
  color: white !important;
  width: 137px;
  height: 50px;
  font-size: 18px !important;
  width: 25%;
  z-index: -1px;
  margin-right: 5px !important;
} 
