.userprofileMain {
  margin-left: 2%;
}

.backIconWeb {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.toolBar {
  padding: 0 !important;
  margin-top: 20px;
}
.r1Wrapper {
  background: #fff;
  padding: 20px 40px;
}
.row{
  display: flex;
}
.column{
  flex: -1;
  padding: 5px;
}
/* User Edit Profile   nandini*/

.EditprofileFrom {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.EditprofilePicImg {
  width: 148px;
  height: 148px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #00D5FE, #0376A6);
  padding: 5px;
  margin-top: 60px;
  margin-left: -389px;
}

.EditprofilePicImage {
  height: 138px;
  border-radius: 50%;
  background-color: white;
  padding: 7px;
  width: 138px;

}

.editPicture {
  margin-top: 235px !important;
  margin-left: -155px !important;
}

.cameraIcon {
  position: relative;
  height: 30px;
  margin-top: 173px;
  margin-left: -41px;
  background: #019BDE;
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

.profileIconBox {
  display: flex;
  justify-content: center;
}

.profileIcon {
  height: 80px !important;
  width: 80px !important;
  margin-top: 60px;
  margin-bottom: 25px;
}

.label {
  color: #1F91C0;
}

.editInputField {
  border: 1px solid #b2b2b2;
  margin-top: 7px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding: 0 15px;
}

.bioInput {
  border: 1px solid #b2b2b2;
  margin-top: 7px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 90px;
  padding: 13px;
  font-size: 14px;
}

.edit {
  position: absolute;
  right: 20px;
  top: 25%;
  color: #359ce2;
  cursor: pointer;
}

.switchEdit {
  position: absolute;
  right: 3%;
  top: 31%;
}

/* userprofile main shivani  */


.userDetail {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.profileContainer {
  margin-top: 20px;
}

.userProfileContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.nameCompany {
  display: flex;
 
  width: 70%;
  justify-content: space-around;
}

.nameCompany2 {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-around;
  margin-left: 5%;
}

.mobileView {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mobileView {
    display: block;
    padding-left: 5px;
    margin-top: -3px;
  }
}


.personalInfo {
  margin-top: 20px;
  width: 72%;
}
/* userbio adhish */
.userBioTitle {
  margin-left: 77px;
}

.profilePicimg {
  display: inline-block;
  border-radius: 50%;
  margin-left: 7%;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #00D5FE, #0376A6);
  padding: 7px;
}

.profilePicImage {
  width: 150px;
  height: 150px;
  background-color: white;
  padding: 6px;
  border-radius: 50%;
}

.profilePicImage2 {
  width: 90px;
  height: 90px;
  background-color: white;
  padding: 6px;
  border-radius: 50%;
}

.profilebio {
  margin-top: 50px;
}

.userName {
  margin-bottom: 0px;
}

.categorymenubuttonstyle {
  border: 1px solid #42a5f5 !important;
  color: #42a5f5 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 5px 10px !important;
}

.selectedcategorymenubuttonstyle {
  border: 1px solid #42a5f5 !important;
  color: #ffffff !important;
  background-color: #42a5f5 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 5px 10px !important;
}

.selectedcategoryConnectedMenubuttonstyle {
  color: #2c2c2e !important;
  background-color: #CFCFCF !important;
  border: none !important;
  font-family: 'Rene_Bieder_Milliard_Semibold' , sans-serif  !important;
  border-radius: 10px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
}

.selectedcategoryPendingMenubuttonstyle {
  color: #269F3C !important;
  background-color: #CDF5EA !important;
  border: none !important;
  border-radius: 10px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.selectedcategoryConnectMenubuttonstyle {
  border: none !important;
  color: #ffffff !important;
  background-color: #42a5f5 !important;
  border-radius: 10px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  font-family: 'Rene_Bieder_Milliard_Semibold' , sans-serif  !important;
  font-size: 14px !important;
}

.cursor {
  cursor: pointer;
}

.selectedcategoryFollowingMenubuttonstyle {
  cursor: default !important;
  background-color: #EEEEEE !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.selectedcategoryAwaitMenubuttonstyle {
  margin-right: 10px !important;
  text-transform: none !important;
  padding: 3px 0px !important;
  font-weight: bold !important;
}

.checkAwaitIcon {
  color: #3eed3e !important;
  border: 0.5px solid #CFCFCF !important;
  border-radius: 100% !important;
  margin-left: 10px !important;
  height: 42px !important;
  width: 42px !important;
  padding: 6px;
}
.mobilecheckAwaitIcon {
  color: #3eed3e !important;
  border: 0.5px solid #CFCFCF !important;
  border-radius: 100% !important;
  margin-left: 5px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 6px;
}


.cancelAwaitIcon {
  color: #ff0000 !important;
  border: 0.5px solid #CFCFCF !important;
  border-radius: 100% !important;
  margin-right: 10px !important;
  height: 42px !important;
  width: 42px !important;
  padding: 6px;
}

.mobilecancelAwaitIcon {
  color: #ff0000 !important;
  border: 0.5px solid #CFCFCF !important;
  border-radius: 100% !important;
  margin-right: 5px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 6px;
}

.connectionsDetails {
  display: flex;
  margin-top: -4%;
  flex-direction: row;
  align-items: center;
  width: 40%;
  margin-left: 27%;
  justify-content: center;
  border-bottom: 1px solid rgb(224 224 224);
}

.connectionsDetails2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(224 224 224);
  padding: 0;
  width: 100%;
  margin-top: 40px;
}

.innerconnection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0px;
  cursor: pointer;
}

.connectionNumbers {
  margin-bottom: 15px;
  color: #009be2;
}

.userBioDetails {
  margin: 2% 15% 0 25%;
}

.userBioDes {
  margin: 10px auto 0 29px; /* Margin-top, Margin-right (20px), Margin-bottom (0) */
  text-align: left;
  width: 63%;
  font-size: 30px;
  color:rgb(153,153,153);
  font-weight: 100;
  word-spacing: 5px;
  max-width: 700px;
padding-left: 6.0%;}



.editProfile {
  margin-top: 15px;
  margin-left: 27%;
  max-width: 100%;
}

.editProfile .editProfileBtn {
  width: 55%;
  text-transform: capitalize;
  background-color: #fff;
  border: 2px solid #00cbf3;
  border-radius: 10px;
}

.editProfile .editProfileMobileBtn {
  width: 100%;
  text-transform: capitalize;
  background-color: #fff;
  border: 2px solid #00cbf3;
  border-radius: 10px;
}


.dash {
  border: 1px solid lightgrey;
  width: 100%;
  height: 0px;
}

/* User Profile Header CSS */

.profileHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  width:100%;
}


.profileHeaderText {
  text-transform: capitalize !important;
  color:#4b4c4d !important;
  font-family: Rene_Bieder_Milliard_Medium !important;

}

.DialogContent {
  height: 480 !important;
  width: auto !important;
  margin: 0px 10px 0px 0;
  padding: 24px 0px 20px 20px !important;
  background: #ffff;
}
.connectionContent {
  height: 480 !important;
  width: auto !important;
  margin: 0px 10px 0px 0;
  padding: 0px 0px 20px 20px !important;
  background: #ffff;
}

.attendedIcon {
  width: 80px;
  height: 80px;
}

.bgContainer {
  cursor: pointer;
  background-image: url('../../../components/assets/images/buttonicon.png');
  background-size: cover;
  background-position: center;
  text-align: center;
  height: 100px;
  /* Set the height of the container */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin-top: -30px;
}

.goldClass {
  background-image: url('../../../components/assets/icons/goldenBadge.svg');
  background-repeat: no-repeat;
  background-size: 75px 75px;
}
.bronzeClass {
  background-image: url('../../../components/assets/icons/bronzeBadge.svg');
  background-repeat: no-repeat;
  background-size: 75px 75px;
}
.silverClass {
  background-image: url('../../../components/assets/icons/silverBadge.svg');
  background-repeat: no-repeat;
  background-size: 75px 75px;
}

.bronzeClass > label {
  color: #FFD48E;
}

.silverClass > label {
  color: #606060;
}
.centeredText {
  color: #D78D14;
  cursor: pointer;
  margin-top: -10px;
  /* Additional styles for your text */
}


.attendedImage {
  width: 100px;
  height: 100px;
}

.logoContent {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  font-weight: bold;
}

.userCompanyLogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 67%;
}


hr {
  height: 1px;
  border: none;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  display: block !important;
}

::-webkit-scrollbar-track {
  background: #ededed !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: #ccc !important;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cacaca !important;
}

.userCompanyLogoImage {
  width: 80px !important;

  object-fit: cover;
  height: 80px !important;
  border: 3px solid black;
}

.attendedBox {
  margin-right: 20px;
}

.profileEditBox {
  width: 80%;
   /* Adjust as needed */
  height: 530px; /* Adjust as needed */
  margin-top: 60px;
  background-color: #F1F2F6;
  overflow: scroll; /* This will add scrollbars as needed */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #757272 #f8f6f6;

}

.profileEditBox::-webkit-scrollbar {
  width: 8px; /* Adjust as needed */
}

.profileEditBox::-webkit-scrollbar-track {
  background: #f0f0f0; /* Adjust as needed */
}

.profileEditBox::-webkit-scrollbar-thumb {
  background-color: #888888; /* Adjust as needed */
}

.imageErrorText {
  color: red !important;
  font-size: 11px !important;
  text-align: end;
}

.loaderRoot {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.nameaddress1{
  margin-left: -35%;
    
}

.circularContainer {
  position: absolute;
  left: 50%;
  top: 41%;
  color: #009BE2;
  transform: translate(-50%, -50%)
}

.circularContainer1 {
  position: absolute;
  left: 60%;
  top: 60%;
  transform: translate(-50%, -50%)
}

.mainContainer {
  margin-left: 5%;
}

.nameCompanyFont{
  margin-left: 0;
}
/* .nameAddressFont{

} */

.profileMob{

  border-radius: 50%;
  border: 5px solid white;
                        
  width: 135;
                        
  height: 135;
  background-color: 'white';
}

.saveMob{
  width:100%;
  background:#F1F2F6;
  padding-top:3%;
  padding-bottom:4%;
  margin-top: 10px; 
  position: sticky;
   z-index: 1000;
   bottom:0;
  
}


@media only screen and (min-width: 600px) and (max-width: 700px) {
  .profilePicImage {
    width: 60px;
    height: 60px;
    background-color: white;
    padding: 3px;
    border-radius: 50%;
  }

}

@media only screen and (max-width: 600px) {
  .mainContainer {
    margin-left: 0%;
  }

  .nameCompanyFont {
    font-size: 16px !important;
     /* Adjust font size as needed */
    color: #000; /* Adjust font color as needed */
    font-weight: bold;
  }

  .nameAddressFont {
    font-size: 10px !important;
  }

  .nameaddress1{
    margin-left: -18%;

  }

  .userprofileMain {
    margin-left: 0px;
  }

  .backIconWeb {
    display: none;
  }


.goldClass {
  background-image: url('../../../components/assets/icons/goldenBadge.svg') !important;
  background-repeat: no-repeat;
  background-size: 55px 55px !important;
}
.bronzeClass {
  background-image: url('../../../components/assets/icons/bronzeBadge.svg') !important;
  background-repeat: no-repeat;
  background-size: 55px 55px !important;
}
.silverClass {
  background-image: url('../../../components/assets/icons/silverBadge.svg') !important;
  background-repeat: no-repeat;
  background-size: 55px 55px !important;
}

  .bgContainer {

    background-image: url('../../../components/assets/images/buttonicon.png');
    background-size: cover;
    background-position: center;
    text-align: center;
    padding-top: 2px;
    height: 60px;
    /* Set the height of the container */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    margin-top: -15px;
  }

  .profilePicimg {
    width: 62px;
    height: 62px;
    margin-left: -25%;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #00D5FE, #0376A6);
    padding: 3px;
  }

  .profilePicImage {
    width: 60px;
    height: 60px;
    background-color: white;
    padding: 3px;
    border-radius: 50%;
  }

  .profileMob{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid white;
    background-color: 'white';
  }


  .saveMob{
    width:100%;
    background:white;
    margin-top: 10px; 
    position: relative;
     z-index: 1000;
     bottom:0;
    
  }


  .personalInfo {
    margin-left: -12%;
    margin-top: 5px;
  }

  .userName {
    font-size: 16px !important;
    margin-bottom: 5px;
  }



  .userCompanyLogoImage {
    width: 40px !important;
    height: 40px !important;
  }

  .userAddress {
    font-size: 13px !important;
    display: ruby;
  }

  .innerconnection {
    margin-right: 10px;
  }

  .connectionName {
    font-size: 12px !important;
  }

  .connectionsDetails {
    margin-top: 30px;
    padding-top: 10px;
    width:100%;
    margin-left: 0%;
    border-top: 1px solid rgb(224 224 224);

  }

  .connectionNumbers {
    margin-bottom: 5px;
  }

  .userBioDetails {

    max-width: inherit !important;
    margin-left: 0% !important;
    width: 95% !important;
    margin-top: 15px !important;
  }

  .userBioDes {
    margin-top: 10px;
    width:100%;
    text-align: left;
    margin-left:0%;
    padding-left: 6%;
  }

  .editProfileMobileBtn{
    width:100%;
    margin-left:0%;
  }

  .mobileFooter {
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  .mobileFooter .connectBtn {
    width: 48%;
    background-color: #009be2;
    color: white;
    border-radius: 10px;
    text-transform: capitalize;
  }

  .mobileFooter .MsgBtn {
    width: 90%;
    text-transform: capitalize;
    margin-left: 5%;
    margin-right: 5%;
    background-color: #fff;
    border: 2px solid #00cbf3;
    border-radius: 10px;
  }
  

  .EditprofileFrom {
    display: flex;
    flex-direction: column;
    margin-top: 18%;
    justify-content: center;
    margin-left: -10px;
  }

  .profileIconBox {
    justify-content: center;
    align-items: center;
    margin-top: -38%;
  }

  .EditprofilePicImg {
    margin-left: 40%;
  }

  .cameraIcon {
    position: relative;
    height: 30px;
  }


  .editProfile .editProfileBtn {
    width: 100%;
  }

  .attendedImage {
    width: 60px;
    height: 60px;
    margin-top: -10px;
  }

  .logoContent {
    margin: 10px;
  }

  .userCompanyLogoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .attendedBox {
    margin-right: 0px;
  }

  .profileEditBox {
    width: 100%;
    margin-left: 17%;
    margin-top: -2% !important;
    height: 67.5vh;
    background-color: white;
  
  }

  .circularContainer {
    position: absolute;
    left: 50%;
    top: 70%;
    color: #009BE2;
    transform: translate(-50%, -50%)
  }

  .DialogContent {
    height: 700px !important;
  }

  .titleprof{
    color:transparent ;
  }
}
/* media screen bio adhish */
@media screen and (max-width: 768px) {
  .userBioTitle {
    margin-left: 30px;
    font-size: 18px;
  }
}

/* Styles for screens with a width of 576px or less */
@media screen and (max-width: 576px) {
  .userBioTitle {
    margin-left: 20px;
    font-size: 16px;
  }
}

/* Styles for screens with a width of 320px or less */
@media screen and (max-width: 320px) {
  .userBioTitle {
    margin-left: 10px;
    font-size: 14px;
  }
}



/* Styles for screens with a width of 576px or less */


/* Styles for screens with a width of 320px or less */


@media screen and (max-width: 600px) {
  .nameCompanyFont,
  .nameAddressFont {
    font-size: 10px; /* Adjust font size for smaller screens */
  }
}

/* Media query for screens with a width of 480px or less */
@media screen and (max-width: 480px) {
  .nameCompanyFont,
  .nameAddressFont {
    margin-left: -20px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    font-size: 8px; /* Adjust font size for even smaller screens */
  }
}

/* Media query for screens with a width of 320px or less */
@media screen and (max-width: 320px) {
  .nameCompanyFont,
  .nameAddressFont {
    margin-left: -20px;
    font-size: 6px; /* Adjust font size for the smallest screens */
  }
}

@media (min-width: 768px) {
  .editProfile .editProfileBtn {
     /* Adjust as needed */
    margin-right: 8%; /* Adjust as needed */
  }
}

/* Responsive adjustments for smaller screens, like mobile */
@media (max-width: 480px) {
  .editProfile .editProfileBtn {
    width: 80%; /* Adjust as needed */
    margin-right: 0;
    margin-bottom: 10px;
  }
}




/* Media query for small screens */
/* @media (max-width: 600px) { */

  .mobileMenu {
    position: absolute;
    width: 40%;
    display: flex;
    top: 8px;
    padding-bottom: 26%;

    padding-right: 4%;
    border: 4px solid transparent;
    margin-left: 60%;
    justify-content: flex-end;
    z-index: 10000;
    align-items: center;
  }
  
  .hamburgerMenu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburgerLine {
    width: 4px;
    border-radius: 50%;
    height: 5px;
    background-color: #333;
    margin: 3px;
  }
  
  .dropdownContent {
    display: flex;
    position: absolute;
    border-radius: 10px;
    margin-right: 10%;
    top: 20px;
    right: 0;
    background-color: white!important;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  

  
  .profileHeaderText1 {
    display: block;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 160px;
    text-align: left;
    border-bottom: 1px solid #eceaea;
    cursor: pointer;
  } 
  .followingContainer{
    display: flex;
     justify-content: space-between;
     align-items: center;
  }
  .flexcontainer{
    display: 'flex';
    flex-direction: 'row';
  }
  .followingErr{
    text-align: "center";
     margin-top: 250;
  }

/* } */