.stickyContained {
  position: sticky;
  top: 0;
  z-index: 1;
}

.dashboardOverflow{
  overflow: hidden;   
}

.groupChatHeight{
  height: 100%;
  overflow: hidden;
}

.groupChatHeight .individualChatMobile {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; 
}

.chatListScroll{
  overflow: scroll;
  height: 100vh;
}

.chatWindowScroll{
  overflow-y: scroll !important;
}

.chatContent {
  margin-top: 30px;
  padding-left: 9px;
  padding-right: 20px;
  overflow-y: auto;
  height: 100%;
  min-height: 80%;
  scroll-behavior: smooth;
}

.avatar {
  width: 80px;
  height: 60px !important;
  border-radius: 50%;
  margin-right: 10px;
}

.textContent {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .chatContent {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-y: unset;
    height: 0%;
    min-height: 0%;
  }

  .chatListScroll{
    overflow: scroll;
    height: calc(100vh - 107px);
    padding-top: 150px !important;
  }

  .margin63 {
    padding-top: 63px !important;
  }

  .chatListScroll>div>div>div:first-child{
    position: fixed !important;
    top: 56px !important;
    z-index: 1 !important;
    /* width: 100%;   */
    padding-right: 10px;
  }

  
  
  .chatWindowScroll{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  .groupChatHeight .individualChatMobile {
    height: unset;
    overflow-y: scroll;
    padding: 0px !important;
  }
  
  .groupSendContainer {
    background: #009be2;
    border-radius: 26px;
    align-items: center;
    margin-right: 10px;
  }

  .groupSendContainer>button{
    padding: 10px;
  }
}

/* indivisual chats  */

.chatsInputMainContainer {
  height: 68px;
  position: relative;
}

.devideLine {
  mix-blend-mode: normal;
  opacity: 0.14;
  border: 1px solid #979797;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.groupFooterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #fff;
}

.groupFooterInput {
  background: #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.groupFooterInput>div>textarea {
  max-height: 10vh !important;
  overflow-y: scroll !important;
}

.groupSendContainer {
  background: #009be2;
  border-radius: 26px;
  align-items: center;
}

.groupIcon {
  height: 26px;
}

.listGroupChat {
  width: max-content !important;
  float: right;
  border: 1px solid #40a9ff;
  background: #40a9ff;
  color: white;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  clear: both;
}

.timeShowingEnd {
  float: right;
  color: white;
}

.listGroupChatOther {
  background: #f7f7f7;
  width: 50% !important;
  margin-bottom: 20px;
  border-radius: 15px;
  overflow-wrap: anywhere;
}

.marginLeft{
  margin-left: auto;
  background-color: #009be2;
  width: unset;
}

.messageArea {
  height: 70vh;
  overflow-y: auto;
}

.messageArea :first-child {
  border: 1px solid blue;
}

.timeShowingOther {
  float: right;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.groupIconInfo {
  height: 150px;
  border-radius: 100%;
  width: 150px;
  object-fit: cover;
}

.groupIconInfoParent {
  margin-top: 40px;
  text-align: center;
}

#groupInfosubTitleText1{
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
  font-weight: 500;
} 

#groupInfosubTitleText2{
  font-size: '24px !important';
  font-weight: bold;
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
  color: rgb(76, 217, 100);
  margin-bottom: 10px;
}

.groupInfosubTitleText2 {
  color: rgb(76, 217, 100);
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

#groupdescription {
  color: #40a9ff;
  margin: 15px 0;
  font-weight: 700;
}

.groupdescriptionText {
  text-align: justify;
  text-justify: auto;
  margin-bottom: 15px !important;
  font-size: 17px;
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
}

.switchParent {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groupInfoMedia {
  height: 100px;
  width: 80%;
  margin-right: 5px;
  margin-bottom: 15px;
}

.groupInfoMember {
  color: #40a9ff;
  font-weight: bold;
  margin-top: 15px !important;
  font-family: Rene_Bieder_Milliard_Medium, sans-serif !important;
}

.groupInfoUserPic {
  border-radius: 100%;
  border: 3px solid #40a9ff;
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.groupInfoUserName {
  /* padding-left: 20px; */
  /* position: relative; */
  /* bottom: 25px; */
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
}

.groupInfoUserChat {
  height: 25px;
  width: 25px;
  margin-top: 3px;
  cursor: pointer;
}

.seenIcon {
  position: relative;
  left: 55px;
  top: 10px;
  color: #ffff !important;
}

.imageMainDiv {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-around !important;
  background-color: white;
}

.imageLists {
  flex-wrap: nowrap !important;
  transform: translateZ(0) !important;
}

#carousel {
  width: 100%;
  height: 150px;
  background-color: #ff0000;
  
  overflow: visible;
  white-space:nowrap;
}

#carousel .slide {
  display: inline-block;
}

.individualChat{
  background-color: #fff;
  position: relative;
  padding: 0px 30px !important;
  width: unset;
  overflow: unset;
  height: 100%;
}

.emojiPicker {
  margin-top: 5px;
}

.dateIssue{
  text-align: center;
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
  color: #8080808a;
}

@media only screen and (max-width: 600px) {
     .groupFooterContainer {
        margin-top: -45px;
        position: fixed;
        right: 0%;
        bottom: 0%;
        left: 0%;
        box-shadow: 0px -2px 4px rgb(0 0 0 / 10%);
        padding: 12px 0;
     }
     
     .toppedDiv {
      margin-top: 15px;
     }

     .SearchBarDiv {
      display: flex;
      align-items: center;
      position: absolute;
      background: #fff;
      width: 100%;
      padding: 0px 0px;
    }

    .marginLeft{
      margin-left: 20vw;
      width: 40% !important;
    }

    .individualChat{
      background-color: #fff;
      position: relative;
      padding: 0px 15px !important;
      width: 100vw;
    }

    .individualChat>div>div:first-child{
      position: fixed;
      top: 0px;
      z-index: 1;
      background-color: #fff;
      padding-left: 10px;
      margin-left: -10px;
    }

    .individualChat>div>div:nth-child(3) {
      position: fixed;
      top: 78px;
      z-index: 1;
      width: 100%;
      right: 1px;
      left: 0px;
    }

    .receiveWidth {
      width: 40% !important;
    }
    
    .emojiPicker{  
      position: fixed;
      bottom: -1;
      width: 95%;
      margin-top: 0px;
      margin-bottom: 50px;
    }
    .emojiPicker>aside{
      height: 340px !important;
      bottom: 13px;
    }

    .dateIssue{
      text-align: center;
      width: 100vw;
      font-family: Rene_Bieder_Milliard_Medium, sans-serif;
      color: #8080808a;
    }

    #groupInfosubTitleText1_ {
      margin-top: 18px;
      margin-bottom: 8px;
      font-size: 20px;
      font-family: Rene_Bieder_Milliard_Medium, sans-serif;
      font-weight: 600;
    }

    .groupFooterInput {
      background: #f7f7f7;
      border-radius: 10px;
      padding: 8px 4px 8px 4px;
      width: 100%;
      margin-left: 10px;
    }

    .groupFooterInput>div>div:first-child>button:first-child {
      padding: 7px 18px 7px 3px;
    }

    .devideLine {
      mix-blend-mode: normal;
      opacity: 0.14;
      border: 1px solid #979797;
      transform: matrix(1, 0, 0, -1, 0, 0);
      margin: 0px -15px;
    }
    .devideLine1 {
      mix-blend-mode: normal;
      opacity: 0.14;
      border: 1px solid #979797;
      transform: matrix(1, 0, 0, -1, 0, 0);
      margin: 0px -15px;
      box-shadow: 1px -1px 7px rgb(0 0 0) !important;
    }
    .devideLine4 {
      mix-blend-mode: normal;
      opacity: 0.14;
      border: 1px solid #979797;
      transform: matrix(1, 0, 0, -1, 0, 0);
      margin: 0px -15px;
      box-shadow: 1px -1px 7px rgb(0 0 0) !important;
    }
    .devideLineGroup{
      mix-blend-mode: normal;
      opacity: 0.14;
      border: 1px solid #979797;
      transform: matrix(1, 0, 0, -1, 0, 0);
      margin: 0px -15px;
      box-shadow: 1px -1px 7px rgb(0 0 0) !important;
    }
    .marginAboveMessage {
      margin: 0px -15px;
      background-color: #fff;
    }
}

.devideLine4 {
  mix-blend-mode: normal;
  opacity: 0.14;
  border: 1px solid #979797;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin: 20px -30px 15px -30px;
  box-shadow: 1px -1px 7px rgb(0 0 0) !important;
}

.devideLine1 {
  mix-blend-mode: normal;
  opacity: 0.14;
  border: 1px solid #979797;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin: 0px -30px;
  box-shadow: 1px -1px 7px rgb(0 0 0) !important;
}

.imageGallery{
  display: flex;
  overflow: auto;
  white-space: nowrap;
}
.individualImageStyle{
  width: 100px;
  height: 80px;
  flex: 0 0 auto;
  margin-right: 10px;
}
.individualImageStyle img{
  height: 100%;
  width: 100%;
  object-fit: fill;
}
*::-webkit-scrollbar {
  width: 10px;
}
.SearchBarDiv {
  display: flex;
  align-items: center;
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 10px 0px;
  z-index: 1;
}
._search_logo {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.textSearch {
  width: 75%;
}
.textSearch>div{
  font-family: Rene_Bieder_Milliard_Medium, sans-serif;
}
.textSearch>div:before{
  border-bottom: 2px solid #c3c3c3a8;
}
.textSearch>div:after{
  border-bottom: 2px solid #c3c3c3a8;
}
.cancelButton {
  color: #009be2;
}
.groupInforDiv{ 
  display: flex;
  align-items: center;
  gap: 15px;
}
.devideLineGroup{
  mix-blend-mode: normal;
  opacity: 0.14;
  border: 1px solid #979797;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin: 0px -30px;
  box-shadow: 1px -1px 7px rgb(0 0 0) !important;
}