.forgotPwdMsgMainContainer {
  background-color: #fff;
}

.forgotPwdMsgImgContainer {
  text-align: center;
}

.forgotPwdMsgImgLogo {
  width: 400px;
  height: 400px;
  margin: 150px 20px;
}

.forgotPwdMsgFormContainer {
  margin: 54px 100px;
  text-align: center;
  background-color: #ffff;
  box-shadow: 1px 6px 30px 1px #c5c5c4;
}

.forgotPwdMsgTitle {
  text-align: center;
  padding: 50px 50px 0px 50px;
  line-height: 30px;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.forgotPwdMsgField {
  color: #000000;
  line-height: 20px !important;
  mix-blend-mode: normal;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  font-size: 20px !important ;
}

.resetPwdBtn {
  height: 56px;
  margin-bottom: 40px !important;
}

.forgotPwdOtpTitle {
  color: #009be2;
}

.otpViewOvalImg {
  height: 150px;
  width: 150px;
  position: relative;
}

.forgotPwdMsgContainer {
  background-image: linear-gradient(#00cbf3, #009be2);
  width: fit-content;
  border-radius: 50%;
  padding: 30px;
  height: 80px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.forgotPwdMsgContainer img {
  height: 100%;
}

.forgotPwdMsg {
  text-align: center;
}

.verifyBtnContainer {
  margin: 135px 25px 10px;
}

@media only screen and (max-width: 1365px) {
  .forgotPwdMsgFormContainer {
    margin: 50px 100px;
  }
}

@media only screen and (max-width: 1279px) {
  .forgotPwdMsgImgLogo {
    width: 350px;
    height: 350px;
  }

  .forgotPwdMsgFormContainer {
    margin: 50px;
  }
}

@media only screen and (max-width: 959px) {
  .forgotPwdMsgMainContainer {
    display: inline !important;
  }

  .forgotPwdMsgImgLogo {
    width: 350px;
    height: 350px;
    margin: 50px 20px 0 20px;
  }

  .forgotPwdMsgFormContainer {
    padding: 0;
    margin: 20px;
    box-shadow: none;
  }

  .forgotPwdMsgTitle {
    margin-top: 0 !important;
    padding: 0;
    text-align: center !important;
    font-size: 20px !important;
  }

  .forgotPwdMsgImgContainer {
    display: none;
  }

  .forgotPwdMsgContainer {
    margin-bottom: 30px;
  }

  .forgotPwdMsg {
  }

  .forgotPwdMsgField {
    font-size: 16px !important;
  }

  .verifyBtnContainer {
    margin: 125px 0 10px;
  }
  
  .successMsgContent {
    width: 100%;
  }

  .successMsgFooterContent button {
    display: block;
  }
}

/* @media only screen and (max-width: 379px) {
  .forgotPwdMsgFormContainer {
    margin: 0;
  }
} */

@media only screen and (max-width: 359px) {
  .forgotPwdMsgImgLogo {
    width: 250px;
    height: 250px;
    margin: 50px 0 0 0;
  }
}
