.nonProfitsCheckbox {
    display: inline-flex;
    align-items: center;
    width: 95%;
    height: 60px;
    margin: 5px;
}

.nonProfitsCheckbox img {
    border-radius: 50%;
    height: 50px;
    width: 60px;
    margin-right: 20px;
}

.searchOrganisationText {
    width: 100%;
}

.searchOrganisationText h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
}

.searchOrganisationText p {
    font-size: 14px;
    color: #a4a3a4;
    font-weight: 700;
}

.currentlyRegisteredAffi p {
    color: #1f95c3;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;

}

.mainNonProfitsBox {
    padding: 10px 30px;
}

.mainNonProfitsBox11 {
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: -20px;
}
.mainDbsBox{
    background-color: #F1F2F6;
    border-radius: 10px;
    padding: 20px;
}

.backIcon {
    display: flex;
    padding: 0px 0px 10px;
    border-bottom: 1px solid #efefef;
    box-shadow: 3px 3px 3px #efefef;
    padding-top:25px;
    padding-left:25px;
    position:sticky;
    top:0;
    background:#F1F2F6;
    z-index: 1000;
}

.backIcon p {
    padding-left: 15px;

    font-family: 'Rene_Bieder_Milliard_Medium';
}

.inputSearch {
    padding: 16 45 16 0;
    font-size: revert;
    /* box-shadow: 0 0 8px rgb(0 0 0 / 12%); */
    margin-top: 8px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 700;
    padding-left: calc(1em + 44px);
    /* margin-bottom: 10px; */
}

.dbsTypesContainer {
    display: flex;
    flex-direction: column;
  }
.npMob{
    padding-top:2%;
    padding-bottom:2%; 
    position: sticky;
    bottom:0;
    background:#F1F2F6;
    z-index: 1000;
}
  



@media only screen and (max-width: 959px) {
    .currentlyRegisteredAffi p {
        color: #1f95c3;
        font-weight: 700;
        font-size: 12px;
        margin-top: 5px;
    }

    .mainNonProfitsBox {

        height: auto;
        padding: 10px 10px;
    }
    

    .backIcon {
        display: flex;
        padding: 10px 10px;
        border-bottom: 1px solid #efefef;
        padding-top:25px;
        padding-left:25px;
        position:sticky;
        top:0;
        background:white;
        z-index: 1000;
    }
}

@media only screen and (max-width: 600px) {
    .nonprofitalignment {
        margin: -10px !important;
        width: 285px;
    }

    .nonprofitalignment1 {
        width: 285px;
    }


    .npMob{
    padding-top:2%;
    padding-bottom:2%; 
    position: sticky;
    bottom:0;
    border:3px solid white;
    background:white;
    z-index: 1000;
}

    .mainDbsBox{
        background-color: white;
        border-radius: 10px;
        height: 30%;
    }

    .searchOrganisationTextTypo {
        font-size: 12px;
    }

    .currentlyRegisteredAffi p {
        margin-bottom: 15px;
    }
}