.groupChat div[class*='MuiFormControl-root']  {
    width: 100%;
    border-radius: 20px;
}
  
.groupChat div[class*='MuiOutlinedInput-root'] {
    border-radius: 15px;
  }

.groupChat .searchField-div {
    margin: 30px 20px 0px 20px;
}

.groupChat input[class*='MuiOutlinedInput-input'] {
    padding: 22px 70px;
    font-family: Rene_Bieder_Milliard_Medium,sans-serif;
    font-size: 20px;
}

.groupChat div[class*='MuiOutlinedInput-root']:hover fieldset[class*='MuiOutlinedInput-notchedOutline']{
    border-color: rgb(0, 155, 226);
}

.groupChat div[class*='MuiOutlinedInput-root']:focus-within fieldset[class*='MuiOutlinedInput-notchedOutline'] {
    border-color: rgb(0, 155, 226);
}

.groupChat .searchIcon {
    position: relative;
    bottom: 50px;
    left: 20px;
    height: 30px;
    float: left;
}

.popoverItems {
    padding: 8px 15px;
    font-size: 14px;
    font-family: Rene_Bieder_Milliard_Book, sans-serif;
}

@media only screen and (max-width: 740px){

    .groupChat div[class*='MuiFormControl-root']  {
        width: auto !important;
        border-radius: 20px;
    }
    .groupChat input[class*='MuiOutlinedInput-input'] {
        padding: 22px 70px;
        font-family: Rene_Bieder_Milliard_Medium,sans-serif;
        font-size: 16px;
        width: 55vw;
    }
    .groupChat div[class*='MuiTabs-flexContainer'] {
        display: flex;
        gap: 15px;
    }
    .groupChat .searchField-div {
        margin: 30px -15px 0px 0px;
    }
    .groupChat .hideChat {
        /* display: none; */
    }
    .groupChat .searchIcon {
        position: absolute;
        top: 85px;
        left: 23px;
    }
    .groupChat .marginRight {
        margin-right: 10px;
    }
}