.searchreletiveenv {
  position: relative;
}

.searchfilterEnv {
  position: absolute;
  width: -webkit-fill-available;
  max-width: 82%;
  margin-top: 56%;
  right: 121px;
  border-radius: 15px !important;
  border: "1px solid lightgray" !important;
}

@media only screen and (max-width: 600px) {
  .tabsAlign {
    margin-left: 0rem;
  }

  .dashboardnavicons {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }
}

.changeLoc {
  margin-right: 40px;
}

@media only screen and (max-width: 600px) {
  .changeLoc {
    margin-right: 0rem;
  }
}

.leftmenuDetails {
  /* background-color: white; */
  height: 100%;
}

/* .userprofileMain{
    margin-left:35px;
    } */
.backIconWeb {
  display: flex;
  align-items: center;
}

.settingContent {
  margin-left: 50px;
}

.tabsRoot > div > div {
  margin-bottom: "50px";
  background-color: white;

  justify-content: space-between;
  padding: 0 3rem;
}

.filterPositions {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate3d(575px, 119px, 0px);
  will-change: transform;
}

.errorMsg .errors {
  text-align: left;
  font-size: 14px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium" , sans-serif !important;
  color: #fd1d1d;
}

@media only screen and (max-width: 600px) {
  .rightmenuDetails {
    display: none;
  }

  .hidebackbutton {
    display: none;
  }

  .mobilemenuposion {
    margin-left: -40px;
  }

  .tabsRoot > div > div {
    margin-bottom: "50px";
    background-color: white;

    justify-content: none;
    padding: 0rem;
  }

  .tabContainer {
    padding: 0px !important;
  }
}

.pastImg {
  margin-top: -36px;
  border-radius: 15px;
}

/* Dashboard CSS Start */

.headerContainer {
  margin: 20px 0;
}

.headerText {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.subHeaderText {
  font-size: 18px !important;
}

.daysCount {
  color: #0096ff;
  font-weight: 500;
}

.subContent {
  padding: 1rem;
  border-radius: 10px !important;
}

.titleContainer {
  font-size: 13px;
}

.digitContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.numberText {
  color: #0096ff;
  font-weight: 600 !important;
}

.percentageText {
  color: #50c878;
  font-weight: 600 !important;
}

.arroWImgSpan {
  cursor: pointer;
  margin: auto 8px;
}

.viewDownImg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.viewUpImg {
  height: 10px;
  width: 20px;
  cursor: pointer;
}
.trialText {
  color: #009065;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 20 !important;
  font-size: 16 !important;
}
.trialDiv {
  background-color: #ffffff;
  border: 1px solid #009be2;
  padding: 10;
  display: flex;
}
.subBtn {
  background: #fefefe !important;
  border: 1px solid #009be2 !important;
  border-radius: 30px !important;
  height: 30 !important;
  margin-left: auto !important;
  margin-right: 36px !important;
  color: #009be2 !important;
  text-transform: none !important;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
}

/* Dashboard CSS End */
