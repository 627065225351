
.dbsandavilsearchMain{
  position: inherit;
  width: inherit;
  height: 100vh;
}

.gridContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}
.MuiPickersCalendarHeader-iconButton-856{
  background-color:  pink !important;
}

.modalInSearchInput{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  height: 54px;
  position: relative;
}


.modalSearchMain{
  position: absolute;
  width: 100%;
  height: 301px;  
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  max-width: 100%;
  z-index: 10;
}


.hidemodalSearchMain{
  /* position: absolute; */
  width: 100%;
  z-index: 4;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  max-width: 100%;
}

.proximitySearchMain{
  width: 100%;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  position: absolute

}

.hideProximitySearchMain{
  /* position: absolute; */
  z-index: 4;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  width: 100%;
}

.modalInProximitySearchInput{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5);
  border-radius: 12px;
  height: 54px;
}


.modalavailibilityLabel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0;
  margin-top:30px;
}

.searchreletiveenv {
  position: relative;
  position: relative;
  width: 1440px;
  height: 1080px;

  background: #f0f2f5;
}

.dbssearchfilterEnv {
  position: absolute;
  /* height: 100vh; */
  /* max-height: 325px; */
  /* width: inherit; */
  top: 49px;
  padding: 10px;

  width: inherit;
  height: 301px;
  /* left: 408px;
top: 284px; */
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.5) !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-button {
  width: 50px;
  /*height: 50px;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(212, 212, 212, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(212, 212, 212, 0.8);
}

.catdropdownmenubackground {
  height: 280px;
  overflow-y: auto;
}

.searchreletiveenv {
  position: relative;
  /* background: #FFFFFF; */
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.8);
  border-radius: 12px;
  z-index: 2;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  -webkit-box-align: center;
  cursor: default;
  -webkit-box-pack: justify;
  transition: all 100ms ease 0s;
  /* overflow-y: auto; */
}

.dbs_tabs_logo {
  width: 18px;
  height: 8px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.dateBtn{
  background-color: transparent;
  border: none;
  min-width: auto !important;
}
