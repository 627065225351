.btns {
  border: 1px solid grey !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  height: 34 !important;
  width: 80;
  font-family: "Rene_Bieder_Milliard_Book" !important;
  font-size: 14px;
}
.labelBold {
  display: inline-block;
  font-family: "Rene_Bieder_Milliard_Medium" !important;
  font-size: 16px;
  margin-bottom: 9px !important;
  padding-left: 10px;
}
.applyBtn {
  background-color: #009be2 !important;
  border-radius: 12px !important;
  height: 43px !important;
  text-transform: capitalize !important;
  float: right !important;
  width: 103 !important;
  border: none !important;
  color: white !important;
  margin-top: 67px !important;
}

.selectedBtn {
    border-radius: 8px !important;
    text-transform: capitalize !important;
    height: 34 !important;
    width: 80;
    font-family: "Rene_Bieder_Milliard_Book" !important;
    font-size: 14px;
    background-color: #009be2 !important;
    color: white !important;
}
.clearBtn {
  border: 1px solid grey !important;
  border-radius: 12px !important;
  height: 43px !important;
  text-transform: capitalize !important;
  float: right !important;
  width: 103px !important;
  margin-top: 67px !important;
}
