.organisationUserMainDiv {
	background-color: #fff;
	margin-top: 30px;
	border-radius: 15px;
}

.organisationUserHeading {
	padding: 35px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.organisationUserHeading h3 {
	/* font-family: "Rene_Bieder_Milliard_Medium"; */
	font-size: 22px;
	font-weight: 400;
}

.profileMainDiv {
	display: flex;
	justify-content: space-between;
	padding: 15px 15px;
}

.profileInformDiv h6 {
	/* font-family: 'Rene_Bieder_Milliard_Medium'; */
	font-size: 16px;
	font-weight: 600;
	line-height: 40px;
}

.profileInformDiv p {
	/* font-family: 'Rene_Bieder_Milliard_Book'; */
	font-size: 14px;
	padding-top: 5px;
}

.crossIconBox {
	height: 35px;
	width: 35px;
	cursor: pointer;
	margin-right: 50px;
}

.crossIconBox img {
	height: 100%;
	width: 100%;
	transform: rotate(41deg);
}

.crossIconBox p {
    font-family: 'Rene_Bieder_Milliard_Light';
    font-size: 14px;
} 

.labelAndInput {
	padding: 5px 15px;
	margin-bottom: 10px;
}

.labelAndInput label {
	font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 40px;
}

.labelAndInput input {
	font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
	padding: 15px 10px;
	border-radius: 12px;
	font-size: 16px;
}

.textFieldDesigned {
	width: 50%;
}

.roleOption {
	width: 100%;
}

.innerTextField {
	border-radius: 10px !important;
}

.buttonMainBox {
	width: 50%;
	display: flex;
	justify-content: space-between;
	padding: 20px 0 20px 15px;
}

.buttonMainBox .saveBtn {
	color: #fff;
	font-size: 18px;
	text-transform: none;
	background-color: #009BE2;
	font-family: 'Rene_Bieder_Milliard_Medium', sans-serif;
	border-radius: 12px;
	width: 45%;
	height: 56px;
}

.buttonMainBox .saveBtn:hover {
	background-color: #009BE2 !important;
}

.buttonMainBox .deleteBtn {
	color: #fff;
	font-size: 18px;
	text-transform: none;
	background-color: #F12323;
	font-family: 'Rene_Bieder_Milliard_Medium', sans-serif;
	border-radius: 12px;
	width: 45%;
	height: 56px;
}

.buttonMainBox .deleteBtn:hover {
	background-color: #F12323 !important;
}

.roleSelect {
	color: red !important;
	margin-left: 27px;
	margin-top: -8px;
}


/* Modal CSS  */ 
.modalWrapper {
	background-color: #8d8d8db0;
}

.modalBox {
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background: #fff;
	max-width: 495px;
	min-width: 384px;
	min-height: 200px;
	padding: 15px;
	border-radius: 12px;
	text-align: center;
	flex-direction: column;
}

.modalBox .modalBtn {
	color: #fff !important;
	background-color: #009be2 !important;
	margin: 25px auto;
	width: 50% !important;
	height: 45px;
	font-size: 18px;
	border-radius: 12px;
}

.modalBox .checkSubmit {
    color: #009be2;
    border: 2px solid #009be2;
    border-radius: 50%;
    padding: 10px;
    height: 55px;
    width: 55px;
    font-size: 25px;
    margin-bottom: 20px;
	margin-top: 30px;
}