.mainContainer {
  justify-content: center;
  align-items: center;
  display: grid !important;
  background-color: white;
}

.formContainer {
  box-shadow: 1px 6px 30px 1px #c5c5c4;
  background-color: white;
  border-radius: 15px;
  width: 30rem;
  align-items: center;
  padding: 2.5rem;
  margin: 50px 0;
}

.otpLogoMainContainer {
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

.otpLogoContainer {
  background-image: linear-gradient(#00cbf3, #009be2);
  width: fit-content;
  border-radius: 50%;
  padding: 30px;
  height: 60px;
  justify-content: center;
  align-items: center;
}

.otpLogo {
  height: 4rem;
  width: 4rem;
}

.congratsText {
  text-align: center;
  margin: 40px 0 20px 0 !important;
  line-height: 30px;
}

.congratsSubText {
  color: #000000;
  line-height: 20px !important;
  mix-blend-mode: normal;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}

.footerBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  margin-top: 10rem !important;
}

@media only screen and (max-width: 600px) {
  .mainContainer{
    justify-content: inherit;
    height: auto;
  }

  .formContainer {
    width: 100%;
    padding: 1.25rem;
    padding: 1.25rem 0;
  }
}