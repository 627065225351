.surveyText {
    font-size: 26 !important;
    font-weight: bold !important;
  }
  .subContent {
    text-align: center;
    padding: 20px;
    border-radius: 10px !important;
  }
  .titleContainer {
    font-size: 13px;
  }
  .titleContainer p {
    color: #000;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
  }
  .titleContainer h5 {
    margin-top: 15px;
    color: rgb(0, 155, 226);
    font-family: "Rene_Bieder_Milliard_Semibold", sans-serif;
  }
  .subContentGradient {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px !important;
    color: #fff;
    background: linear-gradient(rgb(0, 203, 243) 0%, rgb(0, 155, 226) 100%);
  }
  .subContentGradient .titleContainer h5 {
    margin-top: 15px;
    color: #fff;
  }
  .subContentGradient .titleContainer p {
    color: #fff;
  }
  .surveyAccordion > div {
    border-radius: 10px !important;
  }
  .listBroadcasts{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .listDesc {
    margin: 0 30 0 15 !important;
    width: 400px !important;
    overflow-wrap: break-word !important;
    text-align: justify !important;
    color: #000;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
  .listDesc span {
    padding-right: 10px;
  }
  .listWrap {
    margin: 0 30px !important;
    width: 80px !important;
    color: rgb(0, 155, 226);
    font-family: "Rene_Bieder_Milliard_Semibold", sans-serif !important;
  }
  .listTime {
    color: #000;
    margin: 0 10px !important;
    font-family: "Rene_Bieder_Milliard_Semibold", sans-serif !important;
  }
  .subInnerHead {
    font-size: 20px !important;
    padding-left: 30px;
    margin-bottom: 10px !important;
    font-family: "Rene_Bieder_Milliard_Semibold", sans-serif !important;
  }
  .showMoreLink {
    color: rgb(0, 155, 226);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 15px auto !important;
    font-family: "Rene_Bieder_Milliard_Semibold", sans-serif !important;
    cursor: pointer;
  }
  .addQuestionBox .descriptionSubmitBtn {
    background-color: #009BE2;
    box-shadow: none;
    font-size: 16px;
    border-radius: 8px;
    height: 45;
    text-transform: capitalize;
    color: #fff;
    font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif
  }
  .addQuestionBox .descriptionSubmitBtn:hover {
    background-color: #009BE2;
    color: #fff;
    box-shadow: none;
  }
  .addQuestionBox .descriptionSubmitBtn .addIcon {
    font-size: 35px;
    padding-right: 10px;
  }
  .addQuestionBox {
    text-align: center;
    padding: 20px;
  }
  .completeSurveyBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
  }
  .viewresults {
    border-radius: 10px !important;
      border: 1px solid #009be2 !important;
      background-color: #009be2 !important;
      color: #ffffff !important;
      padding: 5px 15px;
      margin-right: 15px !important;
      text-transform: none !important;
  }