 /* Customizable Area Start */
.card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 8px rgb(0 0 0 / 12%);
    border-radius: 15px;
    /* padding: 6px 10px; */
    background-color: #fff;
    width: 100%;
    top: 73px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.mobcard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 8px rgb(0 0 0 / 12%);
    border-radius: 15px;
    padding: 5px 0;
    background-color: #fff;
    width: 100%;
}

.searchIcon{
    height: 30px;
    width: 33px;
    padding: 10px;
}
.searchIcon img{
    height: 100%;
    width: 100%;
}
.arrowIcon{
    height: 30px;
    width: 33px;
    padding: 10px 10px 10px 5px;
}
.arrowIcon img{
    height: 100%;
    width: 100%;
}
.loaderContainer{
    display: flex;
    align-items: center;
    justify-content:  center;
    margin-top:230 ;
    margin-bottom: 180;
  }

.loaderStyle{ 
    height:24 !important;
    width:24 !important;
    color: #009be2 !important;
   
  }  


 /* Customizable Area End */