.followersNPmainDiv{
    background-color: #fff;
    margin-top: 30px;
    border-radius: 15px;
}
.followersNPheadingDiv{
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.followersNPheading h3{
    font-family: "Rene_Bieder_Milliard_SemiBold";
    font-size: 20px;
    font-weight: 400;
}
.modalBox{
    position: absolute;
    height: 300px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #000;
    top: 25%;
    left: 40%;
}
.searchField {
    width: 35rem;
    border-radius: 12px;
    height: 50px !important;
    padding: 10px;
    background: #fafcfe;
    border: 1px solid #dee1ea;
    font-size: 30px;
    font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
    opacity: 0.5;
  }

@media screen and (max-width: 1400px) {
    .searchField {
        width: 25rem;
    }    
} 