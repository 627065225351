.mainWrapper1 {
  display: flex;
  font-family: "Roboto-Medium";
  flex-direction: column;
  align-self: center;
  padding-bottom: 30px;
  background: rgb(255, 255, 255);
  width: 30%;
  height: 100vh;
  border-radius: 13px;
}

.header1 {
  display: flex;
  width: 100%;
  height: 15%;
}
.searchField{
  width: 35rem;
  border-radius: 12px;
  height: 50px !important;
  padding: 10px;
  background: #fafcfe;
  border: 1px solid #dee1ea;
  font-size: 30px;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  
}
.errorMsg .errors {
  text-align: left;
  font-size: 12px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Book",sans-serif;
  color: #ff0000;
}
.header-text1 {
  margin-top: 1rem;
  margin-left: 1rem;
}
.body1 {
  align-items: left;
  padding: 0 10px 0 10px;
  font-family: "Roboto-Medium";
  height: 92%;
}

.body-text-contact1 {
  margin-top: 2rem;
  font-size: 18px;
  margin-bottom: 2rem;
}

.input-fill1 {
  width: 100%;
  border-radius: 13px;
  margin: 0 2rem 0 2rem;
}

.contact-footer1 {
  width: 100%;
  height: 30px;
  margin-top: 60px;
  /* background-color: #009be2  ; */
  /* color: white  ; */
  border-radius: 10px;
  margin-bottom: 10px;
}

.body-header1 {
  margin-top: 10;
  padding: 0 10px 0 10px;
  height: 80%;
}

.body-footer1 {
  height: 20%;
}

.accept-button1 {
  background-color: dodgerblue;
}

.rating-row1 {
  width: 90%;
  padding: 20;
  align-self: center;
}

.emailNotificationTextHeader {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  text-align: start;
}

.emailNotificationText {
  margin-top: 10px !important;
  text-align: start;
}

.autoFlex {
  display: flex;
  justify-content: space-between;
}

.saveBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
}
.disabledTermsBtn {
  background-color: #019BE1 !important;
  color: white !important;
  opacity: 0.6;
  width: 100% !important;
  height: 56px;
  border-radius: 10px !important;
}

.taskPreference {
  height: 50px;
  padding-bottom: 12px !important;
  padding-left: 60px !important;
  border-radius: 15px !important;
  margin-top: 30px !important;
  width: 100%;
  border: 1px solid lightgray
}
.row{
  display: flex;
}
.column{
  flex: -1;
  padding: 1px;
  margin: 5px;
  background-color: #F2F3F5;

}
.Settings5-paper-19 {
  text-align: left !important;
}

.searchIcon {
  position: relative;
  bottom: 40px;
  left: 20px;
}

.MuiOutlinedInput-root-358 {
  border-radius: 15px !important;
}

.preferenceSaveBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-top: 30px !important;
}

@media (max-width: 500px) {
  .mainWrapper1 {
    width: 100%;
  }

  /* .header { */
  /* background-color: blue; */
  /* } */
}

.outline-box1 {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100%; */
  /* justify-content: center; */
  margin-right: 4rem;
}

.border-class1 {
  border: 3px solid #dfdedc;
  background-color: #dfdedc;
}

.signInBtn1 {
  width: 76% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  font-weight: 600;
  border-radius: 10px !important;
  position: absolute !important;
  bottom: 50px !important;
  left: 0;
  right: 0;
  margin: 0 auto !important;
}
.signInBtn1 &:disabled {
  background-color: #019BE1 !important;
  color: white !important;
  opacity: 0.6;
}

.errorMsg .errors {
  text-align: left;
  font-size: 14px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium" !important;
  color: #fd1d1d;
}

.drop-message {
  font-family:  "Rene_Bieder_Milliard_Book" !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.contact-msg-field .MuiOutlinedInput-root-347 {
  border-radius: 12px;
  min-height: 225px ;
  border-radius: 12px;
  border-width: 2px;
  display: flex;
  align-items: baseline;
}

.contact-msg-field {
  margin: 3% 0 2%;
}

.contact-msg-field textarea {
  min-height: 225px !important;
  font-family: 'Rene_Bieder_Milliard_Book';
}

.contact-msg-field .Mui-focused fieldset {
  border-color: #009be2 !important;
}

@media only screen and (min-width: 680px) {
  .mob-header {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .taskPreference{
    height: auto;
    width: auto;
  }
  .contact-msg-field .MuiOutlinedInput-root {
    border-radius: 12px !important;
    border-width: 2px !;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    min-height: 140px !important;
  }
  .contact-msg-field textarea {
    min-height: 140px !important;
  }
  .drop-message {
    margin-top: 6% !important;
  }

  .justify-text{
    padding: 10px 30px;
  }
}

/* Terms And Condition */

.justify-text {
  text-align: justify;
  padding: 0 10px;
}

.termsScroll {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 60vh;
  margin-bottom: 15px;
}
/* .feedbackMsg div[class*='MuiOutlinedInput-root'] {
  border-radius: 12px !important;
} */

.feedbackMsg div[class*='MuiOutlinedInput-root'] {
  border-radius: 12px;
  border-radius: 12px;
  border-width: 2px;
  display: flex;
  align-items: baseline;
}

.feedbackMsg {
  margin: 2% 6% 2% !important;
  width: 87%;
}

.feedbackMsg .Mui-focused fieldset {
  border-color: #009be2 !important;
}

.starRating .feedbackTitle {
  margin-top: 3% ;
  text-align: center ;
  font-size: 19px ;
}

.starRating span[class*='MuiRating-sizeLarge'] {
  font-size: 5rem;
}
@media only screen and (max-width: 600px){
  .starRating span[class*='MuiRating-sizeLarge'] {
    font-size: 4.25rem;
  }
  .starRating .feedbackTitle {
    font-size: 16px !important;
    margin-top: 0;
    text-align: center;
    padding: 7%;
  }
} 
@media screen and (max-width: 600px) {
  .feedbackMsg div[class*='MuiOutlinedInput-root'] {
    min-height: 100px !important;
  }

  .feedbackMsg textarea {
    min-height: 100px !important;
    font-family: 'Rene_Bieder_Milliard_Book';
   
  }
}