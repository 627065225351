.clickText {
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-size: 26 !important;
  padding-left: 40px !important;
  padding-top: 45px !important;
}
.heading {
  display: inline-block;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-size: 22px !important;
  margin-left: 10px;
}
.label {
  display: inline-block;
  font-family: "Rene_Bieder_Milliard_Book", sans-serif !important;
  font-weight: 400px !important;
  font-size: 16px;
  margin-bottom: 5px !important;
  padding-left: 5px;
}
.searchField {
  width: 28rem;
  top:5px;
  border-radius: 12px;
  height: 50px !important;
  padding: 10px;
  background: #fafcfe;
  border: 1px solid #dee1ea;
  font-size: 30px;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif;
}
.tbody {
  display: inline-block;
  font-family: "Rene_Bieder_Milliard_Book", sans-serif !important;
  font-size: 14px !important;
}
.thead {
  display: inline-block;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-size: 12px !important;
}
.btns {
  border: 1px solid grey !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  height: 25 !important;
}
.labelBold {
  display: inline-block;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif !important;
  font-size: 16px;
  margin-bottom: 9px !important;
  padding-left: 39px;
}
.applyBtn {
  background-color: #009be2 !important;
  border-radius: 12 !important;
  height: 40 !important;
  text-transform: capitalize !important;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif !important;
  float: right !important;
  width: 100 !important;
  border: none !important;
}
.dividerLine {
  margin-top: 35px !important;
  background: white !important;
  height: 2px !important;
}

.trialTextGreen {
  color: #009065;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 20 !important;
  font-size: 16 !important;
}
.trialTextRed {
  color: #fd1d1d;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 20 !important;
  font-size: 16 !important;
}

.trialDiv {
  background-color: #ffffff;
  border: 1px solid #009be2;
  padding: 10;
  display: flex;
}
.subBtn {
  background: #fefefe !important;
  border: 1px solid #009be2 !important;
  border-radius: 30px !important;
  height: 30 !important;
  margin-left: auto !important;
  margin-right: 15px !important;
  color: #009be2 !important;
  text-transform: capitalize !important;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
}

.banner{
  cursor: pointer;
  z-index: 1200;
}

/* Subscription Banner CSS */

.mainSubscribeDiv {
  position: relative;
  height: 130px;
  border-radius: 16px;
  background: linear-gradient(-27deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
  background-blend-mode: normal;
  display: flex;
  justify-content: space-between;
}

.bannerImg {
  position: absolute;
  height: 130;
  width: 100%;
  border-radius: 16px;
}

.subText {
  color: #ffffff;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-size: 26px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.noteText {
  color: #ffffff;
  font-family: "Rene_Bieder_Milliard_Medium", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600;
}

.upgradeBtn {
  width: 200px;
  height: 40px;
  border-radius: 6px;
  color: white !important;
  background: #009be2 !important;
  background-blend-mode: normal;
}

.textDiiv {
  margin-top:  30px;
}

.textDiivFreeTrial {
  margin-left: 50px;
  margin-top:  30px;
}

.btnDiv {
  margin-top: 40px;
  margin-right: 50px;
}

.progressDiv {
  margin-top: 25px;
  padding: 0 40px;
}

/* Free Trial Expired Banner CSS */

.mainFreeTrialDiv {
  height: 130px;
  border-radius: 16px;
  background: #ffffff;
  background-blend-mode: normal;
  margin: 30px 25px 0 25px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.freeTrialText {
  font-family: "Rene_Bieder_Milliard_Bold", sans-serif !important;
  font-size: 26px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.subNowBtn {
  width: 200px;
  height: 40px;
  border-radius: 6px;
  color: white !important;
  background: #009be2 !important;
  background-blend-mode: normal;
}

.freeTrialDiv {
   margin-left: 50px;
   margin-top: 30px;  
}
.subNowDiv {
  margin-top: 45px;
  margin-right: 140px;
} 

.verifieEmp {
  font-family: "Rene_Bieder_Milliard_Bold", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.122727275px;
  margin-top: 25px;
}

.infoText {
  color: #87888f;
  font-family: "Rene_Bieder_Milliard_Book", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.10909091px;
}

.progressEmployeeBar {
  width: 100%;
  margin: 10 0;
}

@media screen and (max-width: 1500px) {
  .progressDiv, .textDiiv {
    margin-left: 0;
  }
}

/* @media screen and (max-width: 1400px) {
  .bannerImg {
    width: 74.25%;
  }
}

@media screen and (max-width: 1100px) {
  .bannerImg {
    width: 70%;
  }
} */