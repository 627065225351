/* body {
    overflow: hidden;
} */
.leftmenuDetails{
background-color: white;
height: 100vh;
max-height: 100%;
}

/* .userprofileMain{
margin-left:35px;
} */
.backIconWeb{
    display: flex;
    align-items: center;
}

.backIconWebMail {
    display: flex;
    align-items: center;
}

.backIconWebMail button {
   
}

.backIconWebMail h6 {
    
}

.settingContent{
    margin-left:50px;
}


@media only screen and (max-width: 600px) {
    .rightmenuDetails{
        display:none;
    }
    .hidebackbutton{
        display:none;
    }
    .mobilemenuposion{
       margin-left:-30px;
    }
  }

  .settingsScroll{
    height:98.5vh;
    overflow-x:hidden;
    overflow-y: auto;
}

  @media screen and (max-width: 800px) {

    .settingsScroll{

        height:100vh;
        overflow-x:hidden;
        overflow-y: auto;
}
    
    
      }

  
        
