.firstBox {
    padding: 10px 40px;
}

.planText {
    color: #87888f;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.095454544px;
}
.planType {
    font-family: "Rene_Bieder_Milliard_Bold", sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.17727272px;    
}
.changePlan {
    color: #009be2;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.10909091px;
    margin-right: 10px;
    cursor: pointer;
}
.cancelPlan {
    color: #87888f;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.10909091px;
    cursor: pointer;
}
.subscriptionDaysText {
    font-family: "Rene_Bieder_Milliard_Bold", sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.122727275px;
    margin-top: 40px;
    margin-left: 40px;
}
.progressDaysBar {
    width: 30%;
    margin: 10 0 10 40;
}
.infoText {
    color: #87888f;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.10909091px;
    margin-left: 40px; 
}
.autoRenewtext {
    color: #000501;
    font-family: "Rene_Bieder_Milliard_Book", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 5px;
}
.progressEmployeeBar {
    width: 40%;
    margin: 10 0 10 40;
}