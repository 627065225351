/* Customizable Area Start*/
.searchreletiveenv {
  position: relative;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 13px;
}

.main_carousel {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap based on content */
  justify-content: space-between; /* Distribute cards evenly along the row */
}

.eventPaperCard {
  width: 626px;
  height: 196px;
}

.meetingeventCard {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 16px;
  height: 100px;
}

.searchfilterEnv {
  position: absolute;
  min-height: 100%;
  overflow: auto;
  top: 60px;
  padding: 10px;
  background-color: #f0f2f5;
}

.searchfilterEnvDbSRight {
  padding: 10px;
  width: inherit;
  background-color: #f0f2f5;
  border: 1px solid #D5D5D5;
  border-radius: 10px !important;
}

.changeLoc {
  margin-right: 40px;
}

.leftmenuDetails {
  /* background-color: white;  */
  height: 100%;
}

.tabblock {
  box-sizing: border-box;
  height: 63px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 12px;
}

.backIconWeb {
  display: flex;
  align-items: center;
}

.settingContent {
  margin-left: 50px;
}

.tabsRoot > div > div {
  background-color: white;
  justify-content: space-between;
  padding: 13px 3rem;
  height: 61px;
}

.filterPositions {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate3d(575px, 119px, 0px);
  will-change: transform;
}

.pastImg {
  margin-top: -36px;
  border-radius: 15px;
}

.managemargin {
  margin-left: 26px !important;
  
}

.mobileViewFilterNsearch {
  width: 36px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(212, 212, 212, 0.474186);
  border-radius: 6px;
}

.mobileViewFilterNsearchPosition {
  margin-left: -18px;
}

.readinglocation {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;

}

.favorite {
  z-index: 1;
  position: absolute;
  background-color: white;
  color: black;
  padding: 6px;
  width: 30px !important;
  height: 30px !important;
  right: 12px;
  top: 12px;
  border-radius: 16px;
  font-size: 12px !important;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;
  cursor: pointer;
}

.readinglocationSearchSreen {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 23px 28px 0px 50px;
  margin-bottom: 16px;

}

.locationsreading{
  display:flex;
  justify-content:center;
  align-items: center;
}

.errorMsg .errors {
  text-align: left;
  font-size: 12px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium",sans-serif;
  color: #ff0000;
  margin-top: 10px;
}

.locationSearchField input {
  color: #000 !important;
}

.locationSearchField span {
  background-color: transparent;
}


@media only screen and (max-width: 600px) {
  .tabsAlign {
    margin-left: 0rem;
  }
  .readinglocationSearchSreen {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 15px 14px 0px 13px;
    margin-bottom: 16px;
  
  }
  

  .readinglocation {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-left: -16px;
    margin-top: 10px;
  }

  .mobileViewFilterNsearchPosition {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabsAlignPastEvents {
    margin-left: 10px;
  }

  .changeLoc {
    margin-right: 12px !important;
  }

  .rightmenuDetails {
    display: none;
  }

  .hidebackbutton {
    display: none;
  }

  .mobilemenuposion {
    margin-left: -40px;
  }
}

.event_main_img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.card_carousel_div {
  width: 50%; /* Set the width to 50% to accommodate two columns */
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Stack content from top to bottom */
  align-items: flex-start; /* Align items to the start (top) */
  padding-top: 1.5%;
  padding-left: 3%;
  transform: scaleY(1); /* Original height */


  /* Add any other styles you need */
}

.card_carousel_div1 {
  width: 53%; /* Set the width to 50% to accommodate two columns */
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Stack content from top to bottom */
  align-items: flex-start; /* Align items to the start (top) */
  margin-top: 15px;
  transform: scaleY(1.2); /* Original height */


  /* Add any other styles you need */
}
.card_carousel_div2 {
  width: 47%; /* Set the width to 50% to accommodate two columns */
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Stack content from top to bottom */
  align-items: flex-start; /* Align items to the start (top) */
  margin-top: 15px;
  transform: scaleY(1.2); /* Original height */


  /* Add any other styles you need */
}
.card_carousel_div4 {
  width: 50%; /* Set the width to 50% to accommodate two columns */
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Stack content from top to bottom */
  align-items: flex-start; /* Align items to the start (top) */
  padding-left: 2%;
  margin-left: 2%;
  margin-top: -0.1%;
  transform: scaleY(1); /* Original height */


  /* Add any other styles you need */
}

@media only screen and (max-width: 600px) {
  .card_carousel_div {
    width: 50%;
    transform:none;
    display: block;
    margin-bottom: 20px;
  }

  .event_main_img {
    width: 100%;
    height: 100%;
    max-height: 125px;
    max-width: 120px;
    border-radius: 15px;
    object-fit: cover;
  }

  .managemargin {
    margin-left: 0px !important;
  }

  .tabsAlign {
    margin-left: 0rem;
  }

  .tabblock {
    box-sizing: border-box;
    height: 63px;
    background: #ffffff;
    border-radius: 0px;
    border: 1px solid #f6f6f6;
  }

  .changeLoc {
    margin-right: 0rem;
  }

  .rightmenuDetails {
    display: none;
  }

  .hidebackbutton {
    display: none;
  }

  .mobilemenuposion {
    margin-left: -40px;
  }

  .tabsRoot > div > div {
    margin-bottom: "50px";
    background-color: white;

    justify-content: none;
    padding: 0rem;
  }

  .tabContainer {
    padding: 0px !important;
  }

  .eventPaperCard {
    padding: 0 !important;
    background: none !important;
    border: none !important;
  }

  .cardRightText {
    font-size: 14px !important;
    font-weight: 100 !important;
    line-height: 14px !important;
    letter-spacing: -0.3px !important;
    color: #2d2d2d !important;
    font-family: Rene_Bieder_Milliard_Book, sans-serif !important;
  }

  .cardRightDatenLocation {
    font-size: 12px !important;
    font-weight: 200 !important;
    line-height: 13px !important;
    letter-spacing: -0.3px !important;
    color: #858585;
  }

  .cardRightSubTitleText {
    margin-top: 20px !important;
  }
}

/* Customizable Area End*/
