.backIconWeb{
  display: flex;
  align-items: center;
}
.toolBar {
  display: flex;
  flex: row;
  padding: 0px;
  width: 100%;
  margin-left: 3%;
  padding-top: 18px;
  min-height: 90px !important;
  background-color: #fff;
}
.toolBarInner {
  width: 75%;
  margin: 40px auto;
  height: 78%;
  display: flex;
  flex-direction: column;
}
.toolBarTopHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toolBarInnerIcon {
  color: #fff;
  background-color: #8b00fa;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.toolBarInnerIcon .toolBarInnerimage {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.toolBarInnerIcon .toolBarInnerimage h6 {
  color: #fff;
}
.toolBarInnerIcon .toolBarInnerimage img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 5px;
}
.toolBarInnerIcon .toolBarInnerimage span {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
}
.toolBarInnerHead h4 {
  padding: 25px 0;
  font-family: 'Rene_Bieder_Milliard_Book', sans-serif;
}
.toolBarInnerHead h6 {
  font-family: 'Rene_Bieder_Milliard_Semibold', sans-serif;
}
.descriptionOuter {
  margin: 30px auto;
  background-color: #f8f9fb;
  border-radius: 25px;
  padding: 30px;
  font-family: 'Rene_Bieder_Milliard_SemiBold', sans-serif;
}
.descriptionOuter h5 {
  width: 96%;
  font-size: 20px;
  margin: 0 0 15px;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif !important;
}
.descriptionOuter h6 {
  color: #8b00fa;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif;
}
.descriptionInBox {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 96%;
  margin: 0 auto;
  border: 1px solid #ddd;
}
.descriptionInBox h6 {
  font-family: "Rene_Bieder_Milliard_Book", sans-serif;
  color: #B2B2B2;
  margin: 0;
  line-height: 1.4;
  font-size: 15px;
}
.descriptionSubmitBox {
  width: 85%;
  margin: 0 auto
}
.descriptionSubmitBox .descriptionSubmitBtn {
  width: 100%;
  background-color: #009BE2;
  box-shadow: none;
  border-radius: 8px;
  height: 45;
  text-transform: capitalize;
  color: #fff;
}
.descriptionSubmitBox .descriptionSubmitBtn:hover {
  background-color: #009BE2;
  color: #fff;
}
.radioDescSection {
  display: block !important;
}
.descRadioBox {
  background-color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  width: 70%;
  margin: 10px 0 10px !important;
  border: 1px solid #e5e5e5;
}
.descRadioBox span {
  color: #B2B2B2;
}
.descRadioBox:hover {
  border: 1px solid #009BE2;
}
.descRadioBox:hover span {
  color: #009BE2;
}
.descPagination {
  margin: 20px 0;
}
.descPagination ul {
  justify-content: center;
}
.descPagination ul li:first-child,
.descPagination ul li:last-child {
  display: none !important;
}
.descPagination ul li button {
  color: #B2B2B2;
  background-color: #fff;
  margin: 0 8px;
  border-radius: 8px;
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif
}
.descPagination ul li button:hover,
.descPagination ul li button.Mui-selected {
  color: #009BE2;
  border: 1px solid #009BE2;
  background-color: #fff !important;
}
.Mui-selected {
  color: #009BE2;
}
.descCheckBox {
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 10px;
  width: 70%;
  margin: 10px 0 10px !important;
  border: 1px solid #e5e5e5;
}
.descCheckBox span {
  color: #B2B2B2;
  padding-left: 15px;
}
.descCheckBox:hover {
  border: 1px solid #009BE2;
}
.descCheckBox:hover span {
  color: #009BE2;
}
.descRangeBox {
  margin: 0 2px;
}
.descRangeBox span {
  color: #B2B2B2;
}
.descRangeBox input:checked + div svg {
  color: #009BE2;
}
.descRangeBox:hover span {
  color: #009BE2;
}
.radioWrapper {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioWrapper span {
  font-family: "Rene_Bieder_Milliard_SemiBold", sans-serif
}
.completedSurvey .descriptionOuter {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.completedSurvey .toolBarInnerIcon {
  height: 150px;
  width: 150px;
  margin: 0 auto;
}
.completedSurvey .toolBarInnerimage {
  justify-content: center;
  padding: 12px 40px;
}
.completedSurvey .toolBarInnerimage img{
  margin-bottom: 15px;
  height: 60px;
  width: 60px;
}