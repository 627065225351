.regTitleText {
    margin-bottom: 10px;
    font-weight: 400 !important;
  }
  
  .registerTextField {
    margin-top: 10px !important;
    border-radius: 12px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: "Rene_Bieder_Milliard_Book",sans-serif !important;
  }
  
  .registerInputField {
    margin-top: 10px !important;
    border-radius: 12px !important;
    padding: 18.5px 14px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: "Rene_Bieder_Milliard_Book",sans-serif !important;
  }

  .borderColorChange{
    border: 2px solid #a8a8a88f;
    border-radius: 10px;
  }