.addNewUserBtn{
    display: flex;
    justify-content: flex-end;
}
.addNewUserBtn button{
    border: 1px solid #009be2;
    color: #009be2;
    border-radius: 12px;
    font-size: 18;
    text-transform: none;
    margin-top: 20px;
    padding: 10px 25px;
}
.userTableContainer{
    background-color: pink;
    width: 100px;
    overflow: scroll;
}
.usersNPmainDiv{
    background-color: #fff;
    margin-top: 30px;
    border-radius: 15px;
}
.usersNPheadingDiv{
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.usersNPheading h3{
    font-family: "Rene_Bieder_Milliard_SemiBold";
    font-size: 20px;
    font-weight: 400;
}
.followersNPTableHead tr{
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.usersNPTableHead tr th{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-family: 'Rene_Bieder_Milliard_SemiBold', sans-serif;
}

.tableCell tr td{
    border-bottom: none;
    font-size: 14px;
    cursor: pointer;
}

.usersNPTableCell tr td{
    border-bottom: none;
}
.modalBox{
    position: absolute;
    height: 300px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #000;
    top: 25%;
    left: 40%;
}

.dataNotFound td {
    text-align: center;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-family: 'Rene_Bieder_Milliard_SemiBold', sans-serif;
    padding: 15px;
}