/* Customizable Area Start */
.mainContainer {
  justify-content: center;
  align-items: center;
  display: grid !important;
  background-color: white;
  /* height: 100vh; */
}

.formContainer {
  box-shadow: 1px 6px 30px 1px #c5c5c4;
  background-color: white;
  border-radius: 15px;
  width: 30rem;
  align-items: center;
  padding: 2.5rem;
  margin: 50px 0px;
}

.otpLogoMainContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.otpLogoContainer {
  background-image: linear-gradient(#00cbf3, #009be2);
  width: 168px;
  height: 167px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.otpLogo {
  height: 82px;
  margin: auto;
  width: 82px;
}

.titleText {
  color: #009be2;
  text-align: center;
  line-height: 30px;
  margin: 1rem 0 !important;
}

.otpContainer {
  padding: 5px 30px !important;
  text-align: center;
}

.inputFieldText {
  color: #000000;
  line-height: 21px !important;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 50px !important;
  margin-top: 20px !important;
  text-align: left;
}

.OtpInput input {
  width: 44px !important;
  margin: 2px;
  border: none;
  font: normal normal normal 26px var(--customFontFamily);
  padding: 6px 0 7px;
  min-width: 0;
  border-bottom: 1px solid #b1b1b1 !important;
  background: inherit;
  border-radius: 0;
}

.otpInput input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0 0 0 !important;
}

.otpInput > div:first-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.OtpInput input:focus {
  border-color: #000;
}

.innerOtpText input {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
}

.innerOtpErrorText input {
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Rene_Bieder_Milliard_Book" !important;
}

.footerLinkContainer {
  /* margin: 70px 0 0 !important; */
}

.footerLinkText {
  text-align: center;
  margin-bottom: 20px !important;
}

.footerBtn {
  width: 100% !important;
  height: 56px;
  background-color: #009be2 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  text-transform: capitalize !important;
}

/* Error CSS */
.errorMsg .errors {
  text-align: left;
  font-size: 12px !important;
  padding: 0 !important;
  font-family: "Rene_Bieder_Milliard_Medium";
  color: #ff0000;
}

.errorIconDiv img {
  height: 14px;
  width: 15px;
  margin-right: 7px;
  align-items: center;
}

/* Mobile UI media query */
@media only screen and (max-width: 600px) {
  .mainContainer {
    justify-content: inherit;
    height: auto;
  }

  .formContainer {
    width: 100%;
    padding: 1.25rem;
    padding: 1.25rem 0;
  }
}
/* Customizable Area End */
