/* Customizable Area Start */
button.rec-dot {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #009be2;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 2px #009be2;
  border-radius: 50%;
  outline: none;
}

button.rec-dot:active,
button.rec-dot:focus {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #009be2;
  font-size: 1.3em;
  content: "";
  height: 7px;
  width: 44px;
  box-shadow: 0 0 1px 3px #009be2;
  border-radius: 18px;
  outline: none;
}

.rec.rec-slider-container {
  margin: 0px -50px !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.120465);
  border-radius: 18px;
  background-color: white;
}


@media only screen and (max-width: 768px) {
  body {
    background-color: white !important;
  }

  /* For mobile phones: */
  .rec.rec-slider-container {
    margin: 0px 0px !important;
    box-shadow: none;
  }
}

/* Customizable Area Start */
/* Customizable Area Start */
button.rec-dot {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #009be2;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 2px #009be2;
  border-radius: 50%;
  outline: none;
}

button.rec-dot:active
button.rec-dot:focus {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #009be2;
  font-size: 1.3em;
  content: "";
  height: 7px;
  width: 44px;
  box-shadow: 0 0 1px 3px #009be2;
  border-radius: 18px;
  outline: none;
}

@media only screen and (max-width: 768px) {
  body {
    background-color: white !important;
  }

  /* For mobile phones: */
  .rec.rec-slider-container {
    margin: 0px 0px !important;
    box-shadow: none;
  }
}

/* Customizable Area Start */
